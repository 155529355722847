@import "src/common-ui/variables";

@import "src/common-ui/Button/buttonStyle";

.addCollaboratorModal {
  width: 470px;
  min-width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 8rem);

  @include desktop() {
    min-width: inherit;
    max-width: 98vw;
  }

  .addCollaboratorHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $spacer;

    @include textHeader;

    @include svgSize(1.5rem, 1.5rem);

    @include svgColor($colorDarkGray);

    svg:hover {
      cursor: pointer;
    }
  }

  .closeButton {
    display: flex;
  }

  .addCollaboratorDescription {
    margin-bottom: $spacer;

    @include textBody;
  }

  .addCollaboratorContent {
    overflow: auto;
    margin: 0 (-$spacer);
    padding: 0 $spacer;
    flex-grow: 1;
  }

  .addCollaboratorButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $spacer;
    border-top: $border solid $colorLightGray;
    padding-top: $spacer;
  }
}

.addCollaboratorSubmit {
  @include buttonStyle($colorActionable);
}
