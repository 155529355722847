@import "../../variables";

@import "../../Labels/tagStyle";

@import "../Dropdown/reactSelectStyle";

@include reactSelectBase("tag-input");

@include reactSelectWithMenuOpenUp("tag-input");

:global {
  // Add gap between tags in tag input
  .tag-input__value-container {
    gap: 0.25*$spacer;
    padding: 4px 8px !important;
  }
}

.createLabel {
  display: flex;
  gap: 0.25*$spacer;
  align-items: center;

  @include svgSize($spacer, $spacer);

  @include svgColor($colorGray);
}

.tagChip {
  @include tagStyle(rgba($colorSelected, .1), $colorText, $colorSelected, $colorSelected);

  .tagChipText {
    position: relative;
  }

  .editIcon {
    opacity: 0;
    position: absolute;
    right: -9px;
    top: -3px;
    display: flex;
    background-color: #F8EBFC;
    padding: 4px;
    border-radius: 50%;

    @include svgSize(12px, 12px);

    @include svgColor($colorText);
  }

  .editable:hover {
    text-decoration: underline;

    .editIcon {
      opacity: 1;
    }
  }
}
