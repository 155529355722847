@import "src/common-ui/variables";

.hireCell {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 8px;

  @include transition(.25s);

  &.missing {
    color: $colorMissing;
    @include svgColor($colorMissing);
  }

  &.selected.missing {
    .emptyIcon {
      background-color: $colorSelected;
    }
  }

  &.selected {
    color: $colorSelected;
    @include svgColor($colorSelected);
  }

  .emptyIcon {
    border-radius: 100%;
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $colorAlert;

    @include svgColor($colorWhite);

    @include svgSize(1rem, 1rem);

    &.cancelled {
      background-color: $colorGray;
    }
  }

  &.declinedBorder {
    padding: 2px 8px;
  }
}

.noHire {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: $font-weight-regular;
  @include svgSize(1.25rem, 1.25rem);
  :first-child {
    margin-right: 14px;
  }
}

.addDriverSection {
  flex-direction: column;
}

.declinedBy {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.75rem;
  color: $colorText;
  font-weight: 300;
}
