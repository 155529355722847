@import "src/common-ui/variables";

.dropdownOptionContainer {
  display: flex;
  padding: $spacer $spacer-medium;

  &:not(.disabled):hover {
    background: $colorLightGray;
    cursor: pointer;
  }

  .title {
    @include textSubSubheader;

    margin-bottom: 4px;
  }

  .description {
    @include textBody;
  }

  .displayIcon {
    margin-right: 12px;
  }
}

.disabled {
  cursor: not-allowed;
  border-color: $colorLightGray;
  background-color: $colorLightGray;

  .title {
    @include textSubSubheader($colorMediumGray);
  }

  .description {
    @include textBody($colorMediumGray);
  }
}
