@import "src/common-ui/variables";

@import "src/common-ui/Labels/tagStyle";

// stylelint-disable
@value colorDarkGray #{$colorDarkGray};
// stylelint-enable

.border {
  width: 2rem;
  height: 2rem;
  background-color: $colorSteel;
  padding: $border-thick;
  border-radius: 100%;
  display: flex;
}

.initials {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: $spacer-small*0.5;

  @include textLargeLabel($colorWhite, $font-weight-semibold);

  @include svgColor($colorWhite);
}

.optionLabel {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: 12px;

  .textContainer {
    display: flex;
    flex-direction: column;
    line-height: 1.3em;
    padding-top: $spacer*0.2;
    text-align: left;
    overflow: hidden;

    .optionNameRow {
      .name {
        @include textBody(#454545, $font-weight-semibold);
      }
      .secondary {
        display: inline-block;
        @include textBody(#454545);
      }

    }
    .optionCompanyRow {
      display: flex;
      grid-gap: 2px;

      @include textBody(#ADADAD);
    }
  }
}

.tagChip {
  @include tagStyle(rgba($colorSelected, .1), $colorText, $colorSelected, $colorSelected);

  .tagChipText {
    position: relative;
  }
}

.hoverCard {
  flex-direction: column;
  align-items: stretch;
  grid-gap: 1px;

  background: $colorWhite;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 6px;

  width: 272px;

  .headerContainer {
    display: grid;
    gap: 12px;
    grid-template-columns: auto 1fr;
    padding: 8px;

    .textContainer {
      display: flex;
      gap: 4px;
      flex-direction: column;
      line-height: 1.3em;
      text-align: left;
      overflow: hidden;

      .nameRow {
        @include textBody(#454545, $font-weight-semibold);
      }
      .companyRow {
        @include textBody($colorDarkGray);
      }
    }
  }
  .roleRow {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $colorLightGray;
    align-items: center;
    padding: 8px;

    @include textLargeLabel($color:$colorDarkGray, $fontSize: 13px);

    @include svgColor($colorDarkGray);

    @include svgSize(16px, 16px);
  }
  .phoneRow {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $colorLightGray;
    padding: 8px;

    @include textLargeLabel($color:$colorDarkGray, $fontSize: 13px);

    @include svgSize(16px, 16px);
  }
}

.tooltip {
  pointer-events: auto !important;
  opacity: 1 !important;
  background-color: transparent !important;
  padding: 0px !important;
}
