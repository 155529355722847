@import "src/common-ui/variables";

.detailsTable {
  display: flex;
  flex-direction: column;
  padding: $border 0;
}

.detailsRowLabel {
  padding: calc(#{$spacer-medium} + .1rem) $spacer $spacer-medium 0;
  background-color: $colorWhite;
  width: 150px;
  display: flex;
  align-items: center;

  @include textSmallLabel($colorText, $font-weight-semibold);
}

.detailsRowValue {
  padding: $spacer-medium;
  background-color: $colorWhite;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;

  @include textBody;
}

.detailsRowContainer {
  display: flex
}
