@import "src/common-ui/variables";

.linkShareModal {
  padding: 8px 0;
  display: flex;
  flex-direction: column;

  &.border {
    @include desktop {
      padding-bottom: 25px;
      border-bottom: 1px solid $colorLightGray;
    }
  }

  .description {
    padding: 0 0 $spacer;

    @include textBody;
  }

  .linkContainer {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 120px;
    grid-gap: 5px;
  }

  .linkBox {
    display: flex;
    height: 2.15rem;
    border-radius: $border-radius;
    border: 1px solid $colorSteel;
    padding-left: 10px;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;

    @include textBody($color: $colorMediumGray);

    &.disabled {
      background-color: $colorLightGray;
    }
  }
}

.permissionsContainer {
  padding: 8px 0px;
}

.permissionsHeader {
  padding-bottom: $spacer-medium;
  border-bottom: 1px solid $colorLightGray;

  @include textBold($color: #4D4D4D);

  @include desktop {
    padding-bottom: $spacer;
    border-bottom: 0;
  }
}

.checkbox {
  @include textBody($color: #4D4D4D);

  @include desktop {
    display: grid;
    grid-gap: 16px;
  }
}

.mobileCheckbox {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: $spacer 0;
  border-bottom: 1px solid $colorLightGray;

  > div:nth-of-type(2) {
    margin: 0;
  }
}

.editButtons {
  padding-top: $spacer;
}

.modalHeader {
  display: flex;

  span {
    margin-right: $spacer;
  }
}

.linkHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacer;

  @include textHeader
}

.saveButton {
  margin-top: 2rem;
}

.loading {
  display: flex;
  justify-content: center;
  width: 100%;
}
