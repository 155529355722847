@import "src/common-ui/variables";

.hoverIconContainer {
  border-radius: 50%;
  padding: $spacer-medium;

  @include transition(.25s);

  &:hover {
    cursor: pointer;
    background-color: $colorLightGray;
  }

  .titleInfoIcon {
    display: flex;

    @include svgColor($colorGray);
  }
}

.iconContainer {
  display: flex;
  align-items: center;
  position: relative;

  .notificationsCount {
    z-index: 999;
    position: absolute;
    height: 21px;
    width: 21px;
    top: -20%;
    right: -50%;
    background-color: $colorAlert;
  }
}
