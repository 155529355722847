@import "src/common-ui/variables";

.siteActivityItemWrapper {
  position: relative;
}

// vertical dashed line
.siteActivityItemWrapper::before {
  background-color: white;
}

.siteActivityItemWrapper:last-child {
  background-color: white;
  margin-bottom: 0;
}

.siteActivityItemWrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: start;
  grid-gap: $spacer-medium;
  width: 100%;
  margin-bottom: $spacer;
}

.siteActivityItemTime {
  line-height: 1rem;

  @include textSmallBody;
}

.siteActivityItemDescription {
  line-height: 1.12rem;
  word-break: break-word;

  @include textBody;
}

.siteActivityItemAction {
  font-weight: $font-weight-semibold;
}

.siteActivityItemAvatar {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
}
