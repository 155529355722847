@import "src/common-ui/variables";

.multiProgressBar {

  .multiProgressBarDisplay {
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    width: 100%;

    .multiProgressBackground {
      width: 100%;
      height: 1.25*$spacer-medium;
      background-color: var(--backgroundColor, $colorLightGray);
      overflow: hidden;
    }

    .multiProgressBarData {
      height: 100%;
      background: var(--baseProgressColor, $colorSecondary);
    }
  }

  .multiProgressLegend {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    flex-wrap: wrap;

    .multiProgressLegendValueText {
      @include textBold(var(--textColor, $colorBlack));
    }

    .multiProgressLegendText {
      @include textBody($colorBlack);
    }
  }
}
