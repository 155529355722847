@import "src/common-ui/variables";

@import "src/common-ui/Form/Dropdown/reactSelectStyle";

@include reactSelectBase("collaborator-input");

@include reactSelectWithMenu("collaborator-input");

@value selectHover #{$colorLightGray};

:global {
  // Add gap between tags in tag input
  .collaborator-input__value-container {
    gap: $spacer-medium;
    padding: 8px 12px !important;
    overflow: visible !important;
  }
}

.createLabel {
  display: flex;
  gap: $spacer-medium;
  align-items: center;

  @include svgSize($spacer, $spacer);

  @include svgColor($colorGray);
}
