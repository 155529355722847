@import "src/common-ui/variables";

.projectActionDropdownWrapper {
  display: flex;
  position: relative;
  margin-top: $spacer-small;

  .projectActionDropdown {
    z-index: 999;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    left: auto;
    min-width: 12rem;
    background: $colorWhite;
    border-radius: $border-radius;
    box-shadow: 0 0 7px 0 rgba($colorGray, .5);
  }
}

.dropdownButton {
  padding-right: $spacer-medium;
}

.dropdownValue {
  display: flex;
  padding: $spacer-medium;
  border-color: $colorSelected;
  line-height: normal;
  align-content: center;
  align-items: center;

  @include textSmallLabel($colorBlack, $font-weight-regular, $font-size-small);

  &:hover {
    background-color: $colorHoverGray;

    @include textSmallLabel($colorText, $font-weight-regular, $font-size-small);
  }
}
