@import "common-ui/variables.scss";

.sidebarHeading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: $spacer 0;
}

.notificationDateHeader {
  display: inline-flex;
  padding: 0 $spacer-small;
  background-color: $colorLightGray;
  border-radius: $border-radius;

  @include textSmallLabel;
}

.notification {
  border-radius: $border-radius-large;
  padding: $spacer-medium;
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  box-shadow: 0 1px 5px 1px lightgrey;
  margin-top: $spacer-small;
  margin-bottom: $spacer;

  border: $border solid $colorWhite;
  border-width: $border;

  .notificationTag {
    display: flex;
    align-items: center; 
  }

  &.notificationRequest {
    border-color: $colorBrand;
  }
}

.notificationWithFooter {
  padding: 0;
  flex-direction: column;
  box-shadow: 0 1px 5px 1px lightgrey;
  border-radius: $border-radius-large;
  margin-top: $spacer-small;
  margin-bottom: $spacer;

  .notificationBody {
    padding: $spacer-medium $spacer-medium $spacer-medium;
    border-top-left-radius: $border-radius-large;
    border-top-right-radius: $border-radius-large;
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    margin-bottom: 0;
    border: $border solid $colorWhite;
  }

  .notificationFooter {
    padding: $spacer-small $spacer-medium $spacer-small $spacer-medium;
    border: $border solid $colorLightGray;

    // THIS WAS CHOSEN VERY CAREFULLY !!!
    @include textBody($colorText, $font-size-default, .9213rem);

    .notificationCompanyNumber {
      margin-left: $spacer-small;
      display: block;
      font-weight: $font-weight-xbold;
    }

    .notificationMetadataValue {
      display: inline-block;
      font-weight: $font-weight-normal;
    }
  }
}

.notificationHeader {
  display: flex;
  justify-content: space-between;
  padding: .25rem .5rem;
  border-radius: 6px 6px 0 0;

  @include textSmallLabel;
}

.notificationHeaderName {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.notificationHeaderNameIcon {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0 .5rem 0 0;
}

.notificationContent {
  display: flex;
  flex-direction: column;
  padding: .5rem 1rem;
}

.smartSchedulerNotification {
  display: flex;
  line-height: 1.1;
}

.smartSchedulerBulb {
  background-color: $colorPurple;
  border-radius: 100%;
  height: 32px;
  margin-right: $spacer-small;
  min-width: 32px;
  padding: 0.25rem;

  * {
    fill: $colorBrand;
  }
}
