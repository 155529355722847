@import "src/common-ui/variables";

@import "src/common-ui/Button/buttonStyle";

// Modal
.baseModal {
  display: grid;
  grid-auto-flow: row;
  grid-gap: $spacer;
  width: 325px;
  min-width: 100%;
  max-width: 100%;
}

.baseModalHeader {
  display: flex;
  justify-content: space-between;
}

.baseModalButtons {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-gap: $spacer;
  justify-content: center;
}

.confirmButton {
  @include buttonStyle($colorAlert);
}

.actionButton {
  @include buttonStyle($colorActionable);
}

.secondActionButton {
  @include buttonStyle($colorSecondary);
}

.loadingSpinner::after {
  border: .15rem solid $colorWhite;
  border-color: $colorWhite transparent;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: $font-weight-regular;
  font-size: .9rem;

  div {
    margin-right: $spacer-medium;
  }
}

.splitModal {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 8rem);
}

.splitModalMultiPage {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 6rem);
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 0 $spacer 0;
  position: relative;
}

.headerMultiPage {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: $spacer;
  position: relative;
}

.header,.headerMultiPage {
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: -$spacer;
    height: 1px;
    width: calc( 100% + 2rem);
    background: $colorLightGray;
  }
}

.childContent {
  min-height: 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  flex-grow: 1;

  .childContentColumn {
    overflow-y: scroll;

    &.leftCol {
      padding-right: $spacer;
      padding-top: $spacer;
    }

    &.rightCol {
      padding-left: $spacer;
      padding-top: $spacer;
      padding-right: $spacer;
      margin-right: -$spacer;
      border-left: 1px solid $colorLightGray;
    }

    &.leftColMultiPage {
      padding: $spacer;
    }

    &.rightColMultiPage  {
      padding: $spacer;
      border-left: 1px solid $colorLightGray;
    }

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }
}

.splitModalButtons {
  display: flex;
  justify-content: flex-end;
  padding-top: $spacer;
  position: relative;
}

.splitMultiPageButtons {
  display: flex;
  justify-content: flex-end;
  padding: $spacer;
  position: relative;
}

.splitModalButtons,.splitMultiPageButtons {
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -$spacer;
    height: 1px;
    width: calc( 100% + 2rem);
    background: $colorLightGray;
  }

  button {
    margin-left: $spacer-medium;
  }
}

.close {
  display: flex;

  svg:hover {
    cursor: pointer;
  }

  @include svgColor($colorDarkGray)
}
