@import "src/common-ui/variables";

.rolesLink {
  @include textSmallLabel($colorSecondary);

  &:hover {
    text-decoration: underline;
    color: $colorSecondary;
  }
  &:visited {
    color: $colorSecondary;
  }
}
