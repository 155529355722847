@import "../../variables.scss";

@import "../buttonStyle";

.button {
  &:not(:disabled):hover {
    box-shadow: 0 0 9px 0 rgba($colorSelected, .24);
  }

  &:disabled {
    cursor: not-allowed;
    @include buttonStyle($colorLightGray, $colorGray);
  }
}

.outerCircleParent {
  background: $colorWhite;
  border-color: $colorGray;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  border: 1.6px;

  @include buttonStyle($colorWhite, $colorText, $colorSelected, $colorGray);

}

.iconStyle {
  position: absolute;
  top: 18px;
  right: 20px;
  @include svgColor($colorBlack);
}

.relativeParent {
  position: relative
}

.textStyle {
  position: absolute;
  top: 68px;
  right: 1px;
}
