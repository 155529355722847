@import "src/common-ui/variables";

$colorCustomHoverGrey: #E0E0E0;

.contextSwitcherContainer {
  cursor: pointer;
  display: inline-flex;
  padding: 4px 2px;
  background-color: $colorLightGray;
  border-radius: $spacer-small;
  width: 100%;
  justify-content: center;
}

.unselectedItem {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.15rem;
  border-radius: $border-radius;
  border-width: $border-thick;
  border-style: solid;
  padding: 0 $spacer;
  cursor: pointer;
  white-space: nowrap;
  margin: 0 2px;
  color: $colorText;
  border-color: $colorLightGray;
  background-color: $colorLightGray;
  box-shadow: 0 0 0 0;
  width: 100%;

  @include svgColor($colorText);

  &:hover {
    background-color: $colorCustomHoverGrey;
    border-color: $colorCustomHoverGrey;
  }

  &.selectedItem {
    color: $colorBlack;
    border-color: $colorWhite;
    background-color: $colorWhite;

    @include svgColor($colorBlack);

    &:hover {
      @include svgColor($colorBlack);
    }
  }
}

.buttonIcon {
  display: flex;
  align-items: center;
  padding-right: $spacer-small;
}

.buttonLabel {
  padding-top: $spacer*0.2;

  @include textRegularLabel(inherit);
}
