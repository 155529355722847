@import "src/common-ui/variables";

.emptyContainer {
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  display: grid;
  grid-auto-flow: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  background-size: cover;
}

.loadingSpinner {
  @include spinner($colorText, 2*$spacer-medium + $spacer-medium*0.5);
}
