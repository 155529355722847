@import "src/common-ui/Form/TextInput/styles.module.scss";

@import "src/common-ui/Labels/tagStyle";

.tagInput {
  position: relative;
}

.tagAddButton {
  position: absolute;
  width: 1.5*$spacer;
  height: 1.5*$spacer;
  background-color: $colorGray;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
  right: $spacer-medium;
  top: $spacer-medium;
  padding: $border-thick;
  cursor: pointer;

  @include transition(.2s);

  @include svgColor($colorWhite);

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.tagsList {
  display: flex;
  flex-wrap: wrap;
  padding-top: $spacer-medium;
}

.tagChip {
  cursor: pointer;
  margin-right: $spacer-small;
  margin-bottom: $spacer-small;

  @include tagStyle(lighten($colorLightGray, 4%), $colorText, $colorText, $colorLightGray);
}

.xIndicator {
  display: flex;
  align-items: center;
  padding-top: 3px;

  @include textBody($fontSize: $font-size-large);
}
