@import "src/common-ui/variables";

// Label ROw
.imageContainer {
  position: relative;

  .count {
    position: absolute;
    top: -$spacer-small*0.5;
    right: 0;
    display: inline-flex;
    height: $spacer*1.25;
    width: $spacer*1.25;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    background: $colorSelected;
    color: $colorWhite;
    .label {
      @include textSmallLabel;
      color: inherit;
      position: relative;
    }
  }
}
.image {
  .count {
    position: absolute;
    top: -$spacer-small*0.5;
    right: 0;
    display: inline-flex;
    height: $spacer*1.25;
    width: $spacer*1.25;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    background: $colorSelected;
    color: $colorWhite;
    .label {
      @include textSmallLabel;
      color: inherit;
      position: relative;
    }
  }
}

.image {
  position:relative;
  @include transition(0.25s);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius;
  width: 4rem;
  height: 4rem;
  background: $colorGray;
  border: $border solid $colorGray;
  @include svgColor($colorWhite);

  &:hover {
    background: $colorGray;
  }
  &.uploaded {
    background-size: cover;
    background-position: top;
    &:hover {
      border: $border solid $colorActionable;
    }
  }
}

.spinner {
  @include spinner($colorWhite, $spacer-large);
}
