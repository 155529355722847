@mixin buttonStyle(
  $innerColor: $colorBlack,
  $textColor: $colorWhite,
  $iconColor: $textColor,
  $borderColor: $innerColor
) {
  color: $textColor;

  @include svgColor($iconColor);

  border-color: $borderColor;
  background-color: $innerColor;

  // For some reason this selector is no longer working, but why did we do it this way before?
  &[background],
  [background] {
    border-color: $borderColor;
    background-color: $innerColor;
  }

  &:not(:disabled):hover {
    color: lighten($textColor, 10%);

    @include svgColor(lighten($iconColor, 10%));
    border-color: lighten($borderColor, 10%);
    background-color: lighten($innerColor, 10%);

    &[background],
    [background] {
      border-color: lighten($borderColor, 10%);
      background-color: lighten($innerColor, 10%);
    }
  }

  [data-whatintent="keyboard"] &:not(:disabled):focus {

    &[background],
    [background] {
      box-shadow: 0 0 0 $border $colorWhite,
                  0 0 0 $border-extra-thick $colorSecondary;
      @include transition(0s);
    }
  }

  &:not(:disabled):focus-visible {
    box-shadow: 0 0 0 $border $colorWhite,
                0 0 0 $border-extra-thick $colorSecondary;

    // Override transition timing to none
    @include transition(0s);
  }
}
