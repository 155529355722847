@import "src/common-ui/variables";

$colorCustomHoverGrey: #F3F3F3;

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px $spacer-medium;
  margin: 2px $spacer-medium;

  @include textBody;

  @include svgSize(1.3rem, 1.3rem);

  .label {
    display: flex;
    margin-left: $spacer-small;
    color: $colorDarkGray;
    font-size: $font-size-default;
    font-weight: $font-weight-regular;
  }

  svg * {
    fill: $colorDarkGray;
  }

  &:hover {
    background-color: $colorCustomHoverGrey;
    border-radius: $border-radius;
    border-color: $colorCustomHoverGrey;
  }
}

.activeLink {
  border-radius: $border-radius;
  background-color: $colorLightGray;

  svg * {
    fill: $colorBlack;
  }

  .label {
    color: $colorBlack;
  }
}

.dropdownIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: $spacer-small;
  height: 3rem;
  width: 3rem;
  background-color: $colorLightGray;
  justify-self: flex-end;
  font-size: 1.75rem;
  font-weight: bold;

  @include svgColor($colorText)
}

.indicator {
  display: flex;
  margin-left: .5rem;
  width: 5px;

  svg {
    transform: rotate(-90deg);
  }
}

.dropdownContainer {
  width: 300px;
}
