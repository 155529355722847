@import "src/common-ui/variables";

.errorToast {
  z-index: 999;
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  background-color: rgba($colorBrand, .25);

  @include textRegularLabel($colorBlack);

  a,
  a:visited {
    @include textRegularLabel($colorActionable);
  }

  a:hover,
  a:visited:hover {
    @include textRegularLabel(rgba($colorActionable, .75))
  }
}
