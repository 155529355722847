@import "src/common-ui/Form/TextInput/styles.module.scss";

@import "src/common-ui/Form/Dropdown/styles.module.scss";

@import "src/common-ui/variables";

@import "src/common-ui/Labels/styles.module.scss";

.disabled {
  background-color: $colorLightGray
}

.tagsList {
  display: flex;
  flex-wrap: wrap;
  padding: $spacer-small 0;
}

.tagChip {
  margin: 0 $spacer-small $spacer-small 0;

  @include svgSize(.75rem, .75rem);

  @include tagStyle($colorLightGray, $colorText, $colorText);
}
