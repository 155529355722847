@import "src/common-ui/variables";

@import "common-ui/Form/Dropdown/reactSelectStyle";

.globalSearchBar {
  justify-self: end;
  padding: $spacer-small*1.3 $spacer-small;
  width: 50%;
  overflow: hidden;
  max-width: 280px;
  min-width: 120px;
}

.searchBarValueContainer {
  flex-wrap: nowrap !important;
  overflow-x: hidden !important;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
}

.searchBarTerm {
  background: lighten($colorSelected, 42%);
  padding: 0 $spacer-small;
  margin-right: 2px;
  line-height: normal;
  border-radius: 1rem;
  border: $border solid $colorSelected;
  align-content: center;
  display: flex;
  align-items: center;

  @include textSmallLabel($colorText);

  .clearButton {
    border: 0;
    margin-left: $spacer-small;
    display: inline-flex;
    padding: 0;

    @include svgSize(.75rem, .75rem);

    @include svgColor($colorSelected);
  }

  &:hover {
    color: lighten($colorText, 12%);

    @include transition(.25s);

    .clearButton {
      border: 0;
      margin-left: $spacer-small;
      display: inline-flex;
      padding: 0;

      @include svgSize(.75rem, .75rem);

      @include svgColor(lighten($colorText, 12%));
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.searchPlaceHolder {
  display: flex;
}

@include reactSelectBase("global-searchbar");
