@import 'common-ui/variables.scss';

.sidebar {
  min-width: 20rem;
  padding: 1em;
  height: 100%;
  overflow: auto;
  position: relative;
  background: $colorWhite;
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
}

.sidebarDivider {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  background: linear-gradient($colorLightGray, $colorLightGray) no-repeat center/1px 100%;
}

.sidebarButton {
  margin: 2rem 0;
  padding: 1.5rem 0.25rem;
  border: 1px solid $colorLightGray;
  border-radius: 50%/15%;
  background: $colorWhite;
  display: flex;

  @include svgColor($colorGray);
}

.overlaySidebarPusher {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 100%;

  &_dimed {
    border: 10px solid yellow;
  }
}

.overlaySidebarPushable {
  display: flex;
  flex-grow: 1;
  height: 100%;
}

.formHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacer;
}

.closeIcon {
  height: 24px;
  @include svgSize(1.5 * $spacer, 1.5 * $spacer);

  @include svgColor($colorDarkGray);

  &:hover {
    cursor: pointer;
  }
}



