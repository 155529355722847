@import "src/common-ui/variables";

.searchWrapper {
  flex-direction: column;
  position: relative;
  display: flex;

  .wrapSelection {
    margin: 0;
    padding: 0;
    position: relative;
  }
}

.searchArea {
  display: flex;

  .andOrButton {
    display: flex;
  }
}

.eyeglassIcon {
  position: absolute;
  padding-left: $spacer-small;
  top: auto;
  bottom: auto;
  height: 100%;
  display: flex;
  align-items: center;

  @include svgColor($colorGray);

  @include svgSize($icon-size, $icon-size);
}

.filterList {
  margin-top: $spacer-small;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@mixin selectedItemDiv() {
  padding: 0;
  line-height: normal;
  border-radius: 1rem;
  border: $border solid $colorBlack;
  align-content: center;
  display: flex;
  align-items: center;
  padding-left: $spacer-small;
  padding-right: $spacer-small;
}

@mixin selectedItemDivClearButton() {
  border: 0;
  margin-left: $spacer-small;
  display: inline-flex;
  padding: 0;

  @include svgColor($colorBlack);

  @include svgSize(.75rem, .75rem);
}

.clearAllButton {
  @include textSmallLabel;

  @include selectedItemDiv;

  color: $colorWhite;
  border-color: $colorBlack;
  background: $colorBlack;

  .clearButton {
    @include selectedItemDivClearButton;

    @include svgColor($colorWhite);
  }

  &:hover {
    @include transition(.25s);

    background: $colorText;
  }
}
