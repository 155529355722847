@import "../../variables.scss";

.intercomButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  @include svgSize(1.3rem, 1.3rem);

  @include svgColor($colorGray);

  &.open {
    @include svgColor($colorSelected);
  }
}

.intercomIcon {
  position: absolute;
  top: 0;
  right: $spacer-medium;
  background-color: $colorBlack;
  border-radius: 100%;
  height: $spacer;
  width: $spacer;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  @include textSubheader($colorWhite, $font-size-small);

  &.notificationCount {
    background-color: $colorAlert;

    @include textSmallLabel($colorWhite);
  }

  &.mobile {
    top: $spacer-medium;
  }
}
