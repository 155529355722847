@import "src/common-ui/variables";

.addressInputContainer {
  display: flex;
  align-items: center;
}

.addressField {
  display: inline-block;
  position: relative;
  @include svgSize($spacer, $spacer);

  &.embedded {
    background-color: transparent;
    height: 100%;
    border: 0;
  }
}

.addressTypeIcon {
  &.inputIcon {
    position: absolute;
    margin: 0 0 0 $spacer-small;
    height: 100%;
  }
}

.addressInputField {
  border: 1px solid $colorLightGray;
  border-radius: $border-radius;
  padding: $spacer-medium;
  height: 2.5rem;
  width: 100%;

  @include textBody;
  &.embedded {
    background-color: transparent;
    height: 100%;
    border: 0;
  }

  &.autocompleted {
    padding: $spacer-medium $spacer-medium $spacer-medium calc(2*#{$spacer-small} + #{$spacer});
    @include textRegularLabel;

    &.setcolor {
      color: $colorText;
      font-weight: $font-weight-light;
    }
  }

  &.focused {
    border-radius: $border-radius $border-radius $border-radius 0;

    @include backgroundSelected;

    @include backgroundHover;
  }

  &.disabled {
    background: $colorLightGray;
  }
}

.addressInputField::placeholder {
  @include textBody($colorGray);
}

.autocompleteMenu {
  margin: 0 !important;
  border: $border solid $colorSelected !important;
  border-top: 0 !important;
  border-radius: 0 0 $border-radius $border-radius !important;
  box-shadow: 0 0 7px 0 rgba($colorBlack, .5) !important;
  position: absolute;
  background-color: $colorWhite;
  overflow-y: auto;
  z-index: 999;

  @include textBody;
  @include backgroundSelected;
  width: 100%;

  .groupHeading {
    padding: 0 $spacer-small;
    background-color: $colorLightGray;
    @include textSmallLabel($colorText);
  }

  .googleLogo {
    border-top: $border solid $colorGray;
    display: flex;
    justify-content: flex-end;
    padding: $spacer-small;

    img {
      height: $font-size-small;
    }
  }
}

.option {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: $spacer-small;
  gap: $spacer-small;

  @include textBody;

  &.site {
    @include textRegularLabel;
  }

  &.selected {
    background-color: $colorLightGray;
  }

  &.hovered {
    cursor: pointer;
    background-color: $colorHoverGray;
  }
}

.activeOption {
  background-color: #{transparentize($colorSelected, .75)};
}
