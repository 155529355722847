@import "common-ui/variables.scss";

.shareLiveProjectPage {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.sidebarContainer {
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  grid-gap: 20px;
  min-width: 18.5rem;
  overflow-y: auto;
}

.treadLogo {
  padding-top: $spacer;

  @include textBold($colorBlack, $fontSize: 1.5rem);
}

.legendContainer {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 0;
}
