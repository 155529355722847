@import "../variables.scss";

.container {
  align-items: center;
  border-radius: $border-radius;
  border: $border-thick dashed $colorSteel;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $spacer-large;
  text-align: center;
}

.icon {
  @include svgColor($colorGray);
  @include svgSize(37px, 37px);
}

.title {
  @include textHeader($colorText, 1.125rem);
  margin-bottom: $spacer-medium;
}

.text {
  display: grid;
  justify-items: center;
  line-height: 1.3rem;
  max-width: 250px;

  @include textBody($colorText);
}

.button {
  margin-top: $spacer;
}
