@import "../../variables.scss";

$transitionDuration: .25s;

.checkboxContainer {
  display: flex;
  align-items: center;

  .checkboxLabel {
    margin-left: $spacer-medium;

    @include textBody;
  }
}

.checkboxBlockContainer {
  align-items: center;
  background: $colorLightGray;
  border-radius: $border-radius;
  border: $border-thick solid $colorSteel;
  display: inline-flex;
  padding: $spacer-small $spacer-medium $spacer-small $spacer-small;

  .checkboxLabel {
    margin-left: $spacer-medium;

    @include textBody;
  }

  &.selected {
    background-color: $colorSelected;
    border: $border-thick solid $colorSelected;
    box-shadow: 0 0 7px 0 rgba($colorBlack, .33);

    .checkboxLabel {
      margin-left: $spacer-medium;

      @include textBody($colorWhite);
    }
  }
}

.checkboxControl {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: $border-radius;
  border: $border-thick solid $colorSteel;
  cursor: pointer;
  background: $colorWhite;

  @include transition($transitionDuration);

  @include svgColor($colorSelected);

  svg {
    opacity: 0;
    height: 1rem;
    width: 1rem;

    @include transition($transitionDuration);
  }

  &.selected {
    border: $border-thick solid $colorSelected;
    background-color: lighten($colorSelected, 36%);
    box-shadow: none;

    svg {
      opacity: 1;
    }
  }

  &.partiallySelected {
    svg {
      width: 10px;
      height: 2px;
    }
  }

  &.disabled {
    background-color: $colorLightGray;
    cursor: auto;
  }
}
