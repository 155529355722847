@use "sass:math";

@import "src/common-ui/variables.scss";

@import "src/common-ui/Button/buttonStyle";

.mainContainer {
  height: 100%;
  position: relative;
}

.navHeader {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  justify-content: space-between;
  box-shadow: 0 1px 5px 1px lightgrey;
  z-index: 2;

}

.navHeaderBottom {
  display: grid;
}

.navHeaderTop {
  display: grid;
  border-bottom: 2px solid #EEEEEE;
}

.navHeaderTopLeft {
  display: inline-flex;
  grid-gap: $spacer-small;
  justify-self: start;
  padding-left: $spacer;

}

.homeLink {
  margin: auto;

  @include textBold($colorBlack, $fontSize: 1.5rem);
}

.headerRight {
  padding: 0 $spacer;
}

.headerColumn {
  padding: 0;
}

.headerButton {
  display: flex;
  align-items: center;
  justify-content: center;

  @include svgSize(1.3rem, 1.3rem);

  @include svgColor($colorSteel);

  &.active {
    @include svgColor($colorDarkGray);
  }
}

.hamburgerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: math.div($spacer, 3) 0;
}

.hamburgerLine {
  width: 100%;
  height: $border-thick;
  background-color: $colorGray;
}

.mobileCompanyTitle {
  text-align: center;

  @include textBold($fontSize: 1.25rem);
}

.mobileMenuButton {
  height: 1.5rem;
  width: 1.5rem;

  @include svgSize(100%, 100%);

  @include svgColor($colorGray);
}

.mobileNotificationsIcon {
  display: flex;

  @include svgSize(1.25rem, 1.25rem);

  @include svgColor($colorGray);
}

.mobileHeader {
  display: grid;
  grid-gap: $spacer-medium;
  grid-template-columns: 1fr 3fr 1fr;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 5px 1px lightgrey;
  padding: $spacer-medium $spacer;
  z-index: 4;
}

.mobileIconsContainer {
  display: grid;
  grid-auto-flow: column;
  grid-gap: $spacer;
  justify-self: end;
}

.mobileNavMenu {
  display: flex;
  flex-direction: column;
  flex-grow: .75;
  padding: $spacer $spacer;
  overflow: auto;
  height: 100%;
  width: 100%;
}

.mobileCompanyDropdown {
  padding: 0 0 $spacer 0;
}

.menu {
  display: flex;
  justify-content: center;
}

.navHeaderTopRight {
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  padding: 0 $spacer-small;
}

.notificationsCount {
  z-index: 999;
  position: absolute;
  top: 8px;
  right: $spacer-medium;
  background-color: $colorAlert;
  border-radius: 100%;
  height: $spacer;
  width: $spacer;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  @include textSmallLabel($colorWhite);

  &.mobile {
    top: $spacer-medium;
  }
}

.requestsCount {
  background-color: $colorAlert;
  margin-left: $spacer-small;
  height: 1.3rem !important;
  width: 1.3rem !important;
}

.notificationsLink {
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
}

.hidingHeader {
  max-height: 100%;
  transition: 200ms linear;
  transform-origin: top;

  &.hidingHeaderVisible {
    -webkit-transform: translateY(0%) scaleY(1);
    -moz-transform: translateY(0%) scaleY(1);
    -o-transform: translateY(0%) scaleY(1);
    transform: translateY(0%) scaleY(1);
  }

  &.hidingHeaderHidden {
    max-height: 0;
    -webkit-transform: translateY(-100%) scaleY(0);
    -moz-transform: translateY(-100%) scaleY(0);
    -o-transform: translateY(-100%) scaleY(0);
    transform: translateY(-100%) scaleY(0);
  }
}

.companyLogo {
  margin: auto;
}

.headerLogo {
  padding: $spacer-small 0;
}

.hoverIconContainer {
  border-radius: 50%;
  padding: $spacer-medium;

  @include transition(.25s);

  &:hover {
    cursor: pointer;
    background-color: $colorLightGray;
  }

  .titleInfoIcon {
    display: flex;

    @include svgColor($colorGray);
  }
}
