@import "src/common-ui/variables";

@import "src/common-ui/Button/buttonStyle";

.tagsModal {
  width: 33vw;
  min-width: 100%;
  height: 90vh;
  min-height: 500px;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr) auto;

  .tagsModalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: $spacer;

    @include textSubheader($colorText);
  }

  .closeIcon {
    height: 24px;
    @include svgSize(1.5*$spacer, 1.5*$spacer);

    @include svgColor($colorDarkGray);

    &:hover {
      cursor: pointer;
    }
  }

  .tagsListSection {
    margin: 0 (-$spacer);
  }

  .tagsButtonsSection {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    padding-top: $spacer;

    .manageButton {
      font-size: 14px;
      padding: 0px;
    }
  }
}

.tagList {
  height: 100%;
  border-top: $border solid $colorLightGray;
  border-bottom: $border solid $colorLightGray;
  display: flex;
  flex-direction: column;

  .tagListInput {
    border-bottom: $border solid $colorLightGray;
    display: grid;
    padding-left: $spacer;
    align-items: center;
    grid-template-columns: auto 1fr;

    @include svgColor($colorGray);

    &.icon {
      @include svgSize($spacer, $spacer);
    }
  }

  .tagListInputElement {
    border: 0;

    @include textBody;
  }

  .inputSection {
    padding: $spacer-medium;
    display: flex;
    gap: $spacer-small;
    flex-wrap: wrap;

    .autoInputWrapper {
      flex-grow: 1;

      &:hover {
        cursor: text;
      }
    }
  }

  .tagListItems {
    overflow: auto;
  }

  .createTagOption {
    display: flex;
    gap: $spacer-medium;
    align-items: center;

    @include svgSize($spacer, $spacer);

    @include svgColor($colorDarkGray);
  }
}

.tagListItem {
  padding: 0.75*$spacer $spacer;

  @include textBody;

  @include transition(.1s);

  &:hover {
    cursor: pointer;
    background-color: $colorLightGray;
  }
}
