@import "src/common-ui/variables";

.markerContainer {
  $markerColor: $colorActionable;
  height: 1.5em;
  background-color: $markerColor;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  border-radius: 12px;
  cursor: pointer;
  min-width: 3em;
  max-width: 5.5em;


  &.notAvailable {
    $markerColor: $colorGray;
    background-color: $markerColor;
  }

  &.idle {
    $markerColor: $colorIdle;
    background-color: $markerColor;
  }

  &.badData {
    $markerColor: $colorAlert;
    background-color: $markerColor;
  }

  &.lostConnection {
    $markerColor: $colorBlack;
    background-color: $markerColor;
  }

  &.active {
    z-index: 2;
    width: 1.5em;
    border: 1px solid $colorWhite;
    box-shadow: 0 0 0 4px rgba($markerColor, 0.2);
    transform: scale(1);
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 5px rgba($markerColor, 0.2);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba($markerColor, 0.2);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 5px rgba($markerColor, 0.2);
    }
  }
}

@mixin bearings($markerColor) {

  &.E {
    left: 3.5em;
    top: .3em;
    border-top: .5em solid transparent;
    border-left: .5em solid $markerColor;
    border-bottom: .5em solid transparent;
  }

  &.SE {
    left: 3em;
    top: 1.5em;
    border-left: .5em solid transparent;
    border-right: .5em solid transparent;
    border-bottom: .5em solid $markerColor;
    transform: rotate(135deg);
  }

  &.S {
    left: 1em;
    top: 2em;
    border-top: .5em solid $markerColor;
    border-left: .5em solid transparent;
    border-right: .5em solid transparent;
  }

  &.SW {
    left: -1em;
    top: 1.5em;
    border-left: .5em solid transparent;
    border-right: .5em solid transparent;
    border-bottom: .5em solid $markerColor;
    transform: rotate(225deg);
  }

  &.W {
    left: -1em;
    top: .25em;
    border-top: .5em solid transparent;
    border-right: .5em solid $markerColor;
    border-bottom: .5em solid transparent;
  }

  &.NW {
    left: -.75em;
    top: -.75em;
    border-top: .5em solid transparent;
    border-right: .5em solid $markerColor;
    border-bottom: .5em solid transparent;
    transform: rotate(45deg);
  }

  &.N {
    left: 1em;
    top: -1em;
    border-left: .5em solid transparent;
    border-right: .5em solid transparent;
    border-bottom: .5em solid $markerColor;
  }

  &.NE {
    left: 3em;
    top: -.75em;
    border-top: .5em solid transparent;
    border-left: .5em solid $markerColor;
    border-bottom: .5em solid transparent;
    transform: rotate(-45deg);
  }
}

.bearingIndicator {
  $markerColor: $colorActionable;
  position: absolute;
  width: 0;
  height: 0;

  @include bearings($markerColor);

  &.notAvailable {
    $markerColor: $colorGray;

    @include bearings($markerColor);
  }

  &.idle {
    $markerColor: $colorIdle;

    @include bearings($markerColor);
  }

  &.badData {
    $markerColor: $colorAlert;

    @include bearings($markerColor);
  }

  &.lostConnection {
    $markerColor: $colorBlack;

    @include bearings($markerColor);
  }
}

.markerTextContainer {
  height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  opacity: 1;
  text-align: center;
  white-space: nowrap;
  padding: 0.25 * $spacer; // or 0.5 * $padding-regular

  @include transition(.25s);

  @include textBody($color: $colorWhite, $fontWeight: $font-weight-semibold, $fontSize: 11px);

  &.hidden {
    opacity: 0;
  }
}
