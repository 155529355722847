.emptyMap {
  width: 100%;
}

.mapContainer {
  display: flex;
  height: 100%;
  width: 100%;
  z-index: 0;
  position: relative;
  overflow-x: hidden;

  > div > div > div {
    // Override z-index of actual mapboxgl-map div to drop it lower than the markers
    // So we can properly control the layering of clickable layers
    z-index: -2;
  }
}
