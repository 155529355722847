@import "src/common-ui/variables";
@import "common-ui/Labels/tagStyle";
@import "components/Common/Search/styles.module.scss";

$colorDarkerGray: #4D4D4D;

.popupMain {
    display: grid;
    grid-gap: 10px;
}

.popupSection {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 15px;
    max-width: 190px;
    padding-left: 10px;
}

.popupDetails {
    display: grid;
    grid-gap: 5px;
    line-height: 1.3em;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;

    @include textBody($colorBlack);
}

.popupHeader {
    font-family: "Assistant",
    sans-serif;
    color: $colorText;
    font-size: 16px;
}

.bottomBorder {
    padding-bottom: 12px;
    border-bottom: 1px solid $colorLightGray;
}

.buttons {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 1fr 1fr;
    padding-top: 15px;
}

.saveButton {
    border-color: $colorActionable;
    background-color: $colorActionable;
}

.statusPill {
    @include tagStyle($colorWhite, $colorDarkerGray, $colorGray, $colorGray);
    @include selectedItemDiv;

    .closeIcon {
        @include selectedItemDivCloseButton;
        @include svgColor($colorSelected);
        @include svgSize(1rem, 1rem);
    }

    &.selected {
        @include tagStyle(lighten($colorSelected, 42%), $colorText, $colorSelected, $colorSelected);
        background: lighten($colorSelected, 42%);
    }

    &:hover {
        @include transition(0.25s);
        color: lighten($colorText, 12%);

        .closeIcon {
            @include svgColor(lighten($colorSelected, 12%));
        }
    }
}

.statusText {
    padding: calc(#{$spacer-small}/2 + #{$spacer}/10) 0 $spacer-small*0.5;
    border: 0;
    cursor: pointer;

    @include textSmallLabel(inherit);
}
