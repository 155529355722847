@import "src/common-ui/variables";

@import "src/common-ui/Button/buttonStyle";

.hiresList {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.hireListItem {
  margin: $spacer-small 0;
}

.unassignContainer {
  display: flex;
  flex: 1 1 auto;
  border: $border-thick dashed transparent;
  padding-top: $spacer-medium;

  @include transition(.25s);

  &.hovering {
    border-color: $colorGray;
  }
}

.unassignList {
  flex: 1 1 auto;
}

.hiresToggle {
  margin-bottom: $spacer;
}

.hiresListControls {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto;
  grid-gap: $spacer-medium;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: $colorWhite;
  z-index: 1;

  .sortButton {
    @include buttonStyle($colorWhite, $colorText, $colorGray, $colorSteel);

    &.open {
      @include buttonStyle($colorWhite, $colorText, $colorSelected, $colorGray);
    }
  }

  .sortBody {
    margin-bottom: -$spacer-medium;
  }

  div.popup {
    padding: 0 1.5*$spacer-medium;
  }
}

.hiresListMobile {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $spacer-medium;

  .mobileSearchControl {
    margin: $spacer-medium 0;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;
    grid-gap: $spacer-medium;
    align-items: center;
    margin-top: $spacer;
  }

  .hiresListMobileOptions {
    overflow-y: scroll;
    padding: $spacer-small;
  }
}
