@import "src/common-ui/variables";

.mobileSearchDialog {
  display: flex;

  .searchOnly {
    flex-grow: 1;
    min-width: 3rem;
    margin-right: $spacer-small;
  }
}

.searchDialog {
  display: flex;

  .searchOnly {
    min-width: 16rem;
  }
}

.mobileAndOrButton {
  margin-top: .1rem;
  right: 0;
  margin-left: auto;
}

.andOrButton {
  margin-top: .1rem;
  margin-left: $spacer-small;
}
