@import "src/common-ui/variables.scss";

.container {
  text-align: center;
  width: 520px;
  max-width: 100%;
  padding: $spacer;
}

.largeLogo {
  margin-bottom: $spacer-large * 1.5;

  svg {
    width: 12rem;
    height: auto;
  }
}

.backgroundColorContainer {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.triangles {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.loginCard {
  background: $colorWhite;
  border: $border solid $colorLightGray;
  padding: $spacer;
  margin: $spacer 0;
  border-radius: 3px;
  position: relative;

  @include backgroundHover;
}

.formRowWrapper {
  @include textLargeLabel($colorText);
}

.verifyCodeFormWrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: $spacer-medium;
  align-items: center;

  .verifyCodeFormInputWrapper {
    flex-grow: 1;
  }
}
