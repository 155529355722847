@import "../variables";

@import "./tagStyle";

@import "./countStyle";

// Tag
.tag {
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: $spacer-small * 0.5;
  align-items: center;
  padding: 0.1rem (.75 * $spacer);
  border-radius: 24px;
  line-height: 1rem;
}

.defaultTag {
  @include tagStyle($colorLightGray, $colorText, $colorText);
}

.tagText {
  padding-top: calc(#{$spacer-small}/2 + #{$spacer}/10);
  padding-bottom: $spacer-small*0.5;

  @include textSmallLabel(inherit);
}

.tagIcon {
  display: flex;
  align-items: center;

  svg {
    height: 1rem;
    width: 1rem;
  }
}

// Count
.count {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: $spacer*1.6;
  width: $spacer*1.6;
  border-radius: 100%;
  padding-top: 0.16em;

  @include textSmallLabel($colorWhite);
}

.countAlert {
  @include countStyle;
}

// Pill
.pill {
  display: grid;
}

.pillCount {
  grid-row: 1;
  grid-column: 1;
  z-index: 1;
}

.pillTag {
  grid-row: 1;
  grid-column: 1;
  margin-left: $spacer-medium;
  display: flex;
  align-items: center;
}

.pillSpacer {
  width: 1.5*$spacer-medium;
}
