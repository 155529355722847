@import "src/common-ui/variables";

@import "src/common-ui/Button/buttonStyle";

.createEquipmentHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $spacer;

  @include textHeader;

  @include svgSize(1.5rem, 1.5rem);

  @include svgColor($colorDarkGray);

  svg:hover {
    cursor: pointer;
  }
}

.formInputRow {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-gap: $spacer;
}

.createEquipmentSubmit {
  display: block;
  width: 100%;

  @include buttonStyle($colorActionable);
}
