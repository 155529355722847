@import "src/common-ui/variables";

.cycleCard {
  display: grid;
  grid-template-columns: 12px auto;
  grid-template-rows: auto;
  grid-template-areas:
    "indicator pickup"
    "indicator driving"
    "indicator dropoff";
  gap: 16px 12px;
}

.indicatorArea {
  background-color: $colorLightGray;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  grid-area: indicator;
  justify-content: space-between;
  align-self: center;
  height: 102px;

  > div:first-child {
    position: relative;
    bottom: 1px;
  }

  > div:last-child {
    position: relative;
    top: 5px;
  }
}

.drivingToDropoff {
  background-color: rgba(233, 97, 13, 0.2)
}

.drivingToPickup {
  background-color: rgba(16, 0, 154, 0.2)
}

.iconContainer {
  @include svgSize(12px, 12px);
}

.pickupIcon {
  svg {
    rect {
      fill: $colorIdle;
    }
  }
}

.dropoffIcon {
  svg {
    rect {
      fill: #10009A;
    }
  }
}

.dataContainer {
  font-size: $font-size-small;
  line-height: $font-size-default;
}

.data {
  color: $colorDarkGray;
}

.title {
  font-weight: $font-weight-semibold;
  display: flex;
  align-items: flex-start;

  .iconContainer {
    margin-left: 4px;
  }
}

.pickupArea {
  grid-area: pickup;
}

.drivingArea {
  grid-area: driving;
}

.dropoffArea {
  grid-area: dropoff;
}
