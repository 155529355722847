@import "../variables.scss";

.cardDivider {
  border-bottom: 1.85px solid $colorLightGray;
}

.cardStyle {
  background: $colorWhite;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: $border-radius;
  padding: $spacer;
}


.dividerStyle {
  border-top: 1px solid rgba(34,36,38,.15);
  border-bottom: 1px solid rgba(255,255,255,.1);
  margin: $spacer 0;
}
