@import "common-ui/variables.scss";

.header {
  margin-bottom: $spacer-medium;
}

.topShadow {
  width: 100%;
  height: 1px;
  top: -1px;
  z-index: 1;
  box-shadow: 0 1px 5px 1px lightgrey;
  position: absolute;
}

.mapContainer {
  height: 100%;
  width: 100%;
  z-index: -2;
  position: relative;
  overflow-x: hidden;

  > div > div > div {
    // Override z-index of actual mapboxgl-map div to drop it lower than the markers
    // So we can properly control the layering of clickable layers
    z-index: -2;
  }
}

.emptyMap {
  width: 100%;
}

.liveMap {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-x: hidden;  
  z-index: 0;
}

//Sidebar
// For sidebar - this should be for tablet or larger
.titleSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.siteSidebar {
  min-width: 18.5rem;
  overflow-y: scroll;
}

.infoCards {
  margin-top: $spacer;
}

.filterSites {
  margin: $spacer-medium 0;
}

.legend {
  display: flex;
  gap: $spacer;
  left: auto;
  right: auto;
  flex-direction: row;
  position: absolute;
  bottom: $spacer;
  background: $colorWhite;
  padding: $spacer-medium $spacer;
  border-radius: $border-radius;
  box-shadow: 0px 1.25px 2.5px rgba(0, 0, 0, .2);
}

.legendEntry {
  display: flex;
  align-items: center;
  gap: $spacer-small;
}
