@import "src/common-ui/variables";

.attachment {
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-gap: $spacer-small;
  text-decoration: underline;
  word-break: break-all;

  @include textBody($colorSecondary);

  @include svgColor($colorSecondary);

  svg {
    width: 1rem;
    height: 1rem;
    margin-top: $spacer-small*0.5;
  }
}
