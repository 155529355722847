@import "src/common-ui/variables";

@import "src/common-ui/Button/styles.module.scss";



.iconControl {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1;
}

.topIconControl {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-width: 0;
  border-bottom-width: 0;
}

.middleIconControl {
  border-radius: 0;
  border-bottom-width: $border;
  border-top-width: $border;
}

.bottomIconControl {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-top-width: 0;
}

.bottomMiddleIconControl {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-top-width: $border;
}

.smallButtonBackground {
  background: $colorWhite;
  border-color: $colorSteel;
}
