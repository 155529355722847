@import "src/common-ui/variables";

@import "src/common-ui/Button/buttonStyle";

$tokenWidth: 3.6rem;

.name {
  display: grid;
  grid-template-columns: 9fr 1fr;
  font-family: "Assistant", sans-serif;
  color: $colorText;
  font-size: 16px;
}

.sitesInfoTable {
  padding: 24px 16px 24px 16px;
  @include textSmallBody;
  cursor: default;
}

.sitesInfoHeader {
  align-items: center;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: $spacer-medium;
  max-width: fit-content;
}

.siteIcon {
  display: flex;
  width: 32px;
  height: 24px;
  align-items: center;
}

.arrowIcon {
  @include svgColor($colorDarkGray);
  @include svgSize(.9rem, .9rem);
}

.sitesInfoHeaderText {
  line-height: 1.3em;
  padding-top: $spacer*0.2;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;

  @include textBody($colorGray);
}

.address {
  white-space: nowrap;
}

.sitesInfoSection {
  display: grid;
  padding-top: 6px;
  grid-template-columns: 1fr 1fr;
  grid-gap: $spacer-small;
}

.bottomBorder {
  padding-bottom: 6px;
  border-bottom: 1px solid $colorLightGray;
}

.siteInfoRowLabel {
  display: flex;

  @include textSmallLabel($color: $colorText, $fontWeight: $font-weight-semibold);
}

.siteInfoRowValue {
  display: flex;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;

  @include textBody($fontSize: $font-size-small, $color: $colorText, $fontWeight: $font-weight-regular);

  &.highlightRed {
    @include textBody($fontSize: $font-size-small, $color: $colorAlert, $fontWeight: $font-weight-regular);
  }
}

div.infoPopup {
  min-width: 15rem;
  z-index: 1;

  :global .mapboxgl-popup-content {
    padding: 0;
  }
  &.hovered {
    z-index: 2;
  }
}
