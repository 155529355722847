body {
  margin: 0;
  padding: 0;
  font-family:
    -apple-system,
    linkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#app {
  height: 100%;
}

[data-whatintent="mouse"] *:focus,
[data-whatintent="touch"] *:focus {
  outline: none;
}

/*
This styles the extra wrapping div that is spit out by ReactDOM.render in index.js
 */
#app > div:first-child {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  margin: 0;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    "Courier New",
    monospace;
}

.react-tooltip-style {
  z-index: 9999 !important;
  max-width: 240px;
  font-weight: 400;
  /* requires important to override react-tooltip's default styles */
  padding: 8px 16px !important;
  opacity: 0.8 !important;
  font-size: 14px !important;
  border: 0px !important;
  border-radius: 4px !important;
}

.react-tooltip-style > a,
.react-tooltip-style > a:hover {
  text-decoration: underline;
}

.multi-line {
  line-height: 1.05rem;
  padding: 0 !important;
}
