@import "src/common-ui/variables";

@import "src/common-ui/Button/buttonStyle";

.layerControls {
  position: absolute;
  left: 10px;
  top: 125px;
  z-index: 1;
}

.clickControl {
  display: grid;
  grid-row-gap: $spacer-medium;
}

.showTraffic {
  margin-bottom: $spacer-small;
}

.layerControlWrapper {
  display: flex;
}

.layerControlDialogWrapper {
  display: grid;
  padding: 24px 16px;
  grid-row-gap: $spacer;
  border-radius: $border-radius;

  @include backgroundClick;
}

.layerControlButton {
  @include buttonStyle($colorWhite, $colorWhite, $colorText, $colorSteel);

  &:not(:disabled):hover {

    &[background],
    [background] {
      border-color: $colorSteel;
      background-color: rgba(238, 238, 238, .6);
    }
  }

  &.active {

    &[background],
    [background] {
      background-color: $colorLightGray;
    }
  }
}
