@import "src/common-ui/variables";

$bottomMargin: 3rem;
$tokenWidth: 3.6rem;

.mapPopupContainer {
  font-family: $font;
  background: $colorWhite;
  min-width: 14rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.mapPopupTitle {
  font-size: $font-size-small;
}

@mixin textPopup() {
  font-weight: $font-weight-regular;
  font-size: $font-size-small;
}

.textPopup {
  @include textPopup;
}

.mapPopupJobID {
  @include textBold;

  @include textPopup;
}

@mixin mapPopupSubtitle() {
  @include textPopup;
}

.mapPopupSubtitle {
  @include mapPopupSubtitle;
}

.mapPopupPhoneNumber {
  @include textPopup;
}

.mapPopupCompany {
  color: $colorBlack;

  @include mapPopupSubtitle;
}

.infoSection {
  padding: $spacer-medium 0;
  font-size: $font-size-small;
  line-height: $font-size-default;

  &.bottomBorder {
    border-bottom: 1px solid $colorLightGray;
  }

  &:last-child {
    border-bottom: 0;
  }
}

.projectInfoRow {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  padding-bottom: $spacer-medium;

  &:last-child {
    padding-bottom: 0;
  }

  > div:last-child {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.projectInfoTitle {
  @include textBold($color: $colorText, $fontSize: $font-size-small);
}

.infoPopup {
  // Semantic UI globally sets this to "inherit", which prevents the card from
  // expanding to the intended width. Setting this to the default "initial"
  // fixes the layout.
  box-sizing: initial;
  min-width: 172px;
  max-width: 210px;
  padding: $spacer;
  z-index: 1;
  cursor: default;

  :global .mapboxgl-popup-content {
    padding: 0;
  }

  &.mobile {
    min-width: 250px;
    max-width: 350px;
  }

  &.hovered {
    z-index: 2;
  }
}

.icon {
  width: 1.25rem;
  border-radius: $border-radius;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @include svgColor($colorGray);

  svg {
    height: .8rem;
    width: .8rem;
  }
}

.opened {
  svg {
    transform: rotate(90deg);
  }
}

.closed {
  svg {
    transform: rotate(-90deg);
  }
}

.viewRoutesInfoContainer {
  margin-top: $spacer;
}

.idleIcon,
.lostIcon {
  margin-right: 4px;
}

.lostIcon {
  @include svgColor($colorBlack);
}

.viewRouteInfoFont {
  @include textBody($colorSecondary, $font-weight-regular, 14px);
}

.dropdown {
  font-weight: $font-weight-semibold;
  display: flex;
  cursor: pointer;

  .icon {
    margin-left: 4px;
  }
}
