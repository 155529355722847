@import "common-ui/variables.scss";

.page {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: row;
  min-height: 0; // Fix for Firefox div sizing bug
}

.mobilePage {
  position: relative;
  display: flex;
  flex: 1;
  overflow-y: auto;
  flex-direction: column;
}
