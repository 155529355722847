@import "../../variables.scss";

.radioItemsGroup {
  display: grid;
  grid-gap: 12px;
  padding-top: 9px;

  @include textBody;

  &.horizontal {
    grid-auto-flow: column;
  }
}

label > span {
  display: flex;
  flex-direction: row;
  align-items: center;
}

label > span + div {
  padding-left: 1.5rem;
  margin-left: 7px;
}

label > input[type="radio"] {
  display: none;
}

label > input[type="radio"] + *::before {
  display: inline-block;
  cursor: pointer;
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 7px;
  border-radius: 50%;
  border-radius: 50%;
  border: $border-thick solid $colorSteel;
  background-position: center;
  background-repeat: space;

  @include transition(.25s);
}

label > input[type="radio"]:disabled + *::before {
  background-color: $colorLightGray;
}

label > input[type="radio"]:checked + *::before {
  background-image: radial-gradient($colorSelected 35%, rgba(181, 56, 222, 0.1) 40%);
  border: $border-thick solid $colorSelected;
}

label > input[type="radio"]:checked:disabled + *::before {
  background-image: radial-gradient($colorSteel 35%, $colorLightGray 40%);
  border: $border-thick solid $colorSteel;
}
