@import "src/common-ui/variables";

@import "src/common-ui/Labels/tagStyle";

.tag {
  border-radius: 24px;
  padding: 0rem .5rem;
  @include textSmallLabel(inherit, $font-weight-regular, 12px);
}

// Send Status
.missing {
  @include tagStyle($colorMissing, $colorText);
}

.declined {
  @include tagStyle($colorBlack);
}

.draft {
  @include tagStyle($colorSecondary);
}

.sent {
  margin-right: $spacer-small;

  @include tagStyle($colorLightGray, $colorBlack, $colorActionable);
}

.cancelled {
  @include tagStyle($colorBlack);
}

// Assignment Status
.pending {
  @include tagStyle($colorGray);
}

.accepted {
  @include tagStyle($colorActionable);
}

.logging {
  @include tagStyle($colorBrand, $colorText);
}

.paused {
  @include tagStyle($colorIdle, $colorWhite, $colorWhite);
}

.done {
  @include tagStyle($colorLightGray, $colorText);
}

.ended {
  @include tagStyle($colorBlack, $colorWhite);
}

.unassigned {
  @include tagStyle($colorAlert, $colorWhite);
}

// InstanceStatus
.terminated {
  @include tagStyle($colorLightGray, $colorText);
}

.reassigned {
  @include tagStyle($colorDarkGray);
}

// Priority
.high {
  @include tagStyle($colorAlert);
}

.medium {
  @include tagStyle($colorIdle);
}

.low {
  @include tagStyle($colorGray);
}

// Select
.selected {
  @include tagStyle($colorSelected);
}

// Notifications Types = 'request' | 'declined' | 'idle' | 'online' | 'lost' | 'accepted' | 'info';
.notifications {

  &.online,
  &.info {
    @include tagStyle($colorGray);
  }

  &.accepted {
    @include tagStyle($colorActionable, $colorWhite);
  }

  &.acceptedRequest {
    @include tagStyle($colorLightGray, $colorText, $colorWhite);
  }

  &.lost {
    @include tagStyle($colorBlack);
  }

  &.request {
    @include tagStyle($colorLightGray, $colorText, $colorBlack);
  }

  &.idle {
    @include tagStyle($colorBrand);
  }

  &.declined {
    @include tagStyle($colorAlert, $colorWhite);
  }

  &.declinedRequest {
    @include tagStyle($colorLightGray, $colorText, $colorWhite);
  }

  &.added {
    @include tagStyle($colorSecondary);
  }
}

.subdispatchIconRed,
.subdispatchIconGreen,
.subdispatchIconYellow {
  margin-left: -1*$spacer-small;
  display: inline-flex;
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
  border-color: $colorBrand;
  background-color: $colorBrand;
  vertical-align: text-top;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.subdispatchIconRed {
  border-color: $colorAlert;
  background-color: $colorAlert;
}

.subdispatchIconGreen {
  border-color: $colorActionable;
  background-color: $colorActionable;
}
