@import "../../variables";

@import "./reactSelectStyle";

.dropdownIndicator {
  margin: $spacer-medium;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  justify-content: center;

  @include svgColor($colorGray);

  svg {
    height: .8rem;
    width: .8rem;
    transform: rotate(-90deg);
  }

  &.open {
    background-color: transparent;

    @include svgColor($colorBlack);

    svg {
      height: .8rem;
      width: .8rem;
      transform: rotate(90deg);
    }
  }
}

.valueList {
  margin-top: $spacer-small;
  display: flex;
  flex-flow: wrap;
  align-items: flex-start;

  .selectedValue {
    display: flex;
    padding: 0 $spacer-small 0;
    border-color: $colorSelected;
    border-radius: 1rem;
    background: $colorLightGray;
    margin: 0 $spacer-small $spacer-small 0;
    line-height: normal;
    align-content: center;
    align-items: center;

    @include textSmallLabel($colorBlack, $font-weight-regular, .8rem);

    .clearButton {
      border: 0;
      margin-left: $spacer-small;
      display: inline-flex;
      padding: 0;

      @include svgSize(.75rem, .75rem);

      @include svgColor($colorGray);
    }
  }
}

@include reactSelectBase('react-select');

@include reactSelectWithMenu('react-select');
