@import "src/common-ui/variables";

.modalOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  overflow: auto;

  @include desktop() {
    padding: 3rem;
    background-color: rgba(0, 0, 0, .5);//The dark background is not applicable for mobile view
  }
}

.modalContent {
  background-color: $colorWhite;
  display: inline-block;
  padding: $spacer;
  border-radius: 6px;
  margin: auto;
  min-width: 100vw;
  max-width: 100vw;

  @include desktop() {
    min-width: 20vw;
    max-width: 1600px;
  }
}
