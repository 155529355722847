@import "common-ui/variables.scss";

@import "src/common-ui/Button/buttonStyle";

.overviewContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.mainContent {
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.sidebarContent {
  overflow-y: auto;
  // Subtract header height and menu height
  height: calc(100vh - 48px - 3.5rem);
  width: 100%;
  overflow-x: hidden;
  right: 0;
  left: 0;
  padding: $spacer $spacer-medium 0 $spacer-medium;
  background-color: $colorWhite;
}

.resetZoomButton {
  margin-right: $spacer-small;
}

.hideMap {
  position: absolute;
  padding-bottom: 3.5rem;
  visibility: hidden;
}
