@import "../variables.scss";
@import "./buttonStyle";
.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.15rem;
  border-radius: $border-radius;
  border-width: $border-thick;
  border-style: solid;
  padding: 0 $spacer;
  cursor: pointer;
  white-space: nowrap;

  @include transition(.25s);

  svg {
    height: 1rem;
    width: 1rem;

    path {
      @include transition(.25s);
    }
  }

  &:not(:disabled):hover {
    box-shadow: 0 0 9px 0 rgba($colorSelected, .24);
  }

  &.fullWidth {
    width: 100%;
  }

  &:disabled {
    cursor: not-allowed;

    @include buttonStyle($colorLightGray, $colorMediumGray);
  }

  &.large {
    height: 3.3rem;

    @include svgSize(1.5rem, 1.5rem);

    .buttonIcon {
      padding-right: $spacer-medium;
    }

    .buttonLabel {
      padding-top: 0;

      @include textSubheader(inherit, 1.1rem);
    }
  }
}

.buttonIcon {
  display: flex;
  align-items: center;
  padding-right: $spacer-small;

  &.right {
    padding-right: 0;
    padding-left: $spacer-small;
  }
}

.buttonLabel {
  padding-top: $spacer*0.2;

  @include textRegularLabel(inherit);
}

// Small Button
.smallButton {
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: $border-radius;

  &,
  [background] {
    @include transition(.25s);
  }

  &:disabled {
    cursor: not-allowed;

    @include buttonStyle($colorLightGray, $colorMediumGray);
  }
}

.smallButtonIcon {
  width: 2.2rem;
  height: 2.2rem;
  border: $border-thick;
  border-style: solid;
  border-radius: $border-radius;
  border-color:inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  @include buttonStyle($colorLightGray, $colorMediumGray);

  svg {
    height: 100%;
    width: 100%;
    padding: $spacer-small;

    path {
      @include transition(.25s);
    }
  }
}

.smallButtonLabel {
  padding-left: $spacer-medium;

  @include textSmallLabel(inherit);
}

.smallTextOnlyButtonLabel {

  @include textSmallLabel(inherit);
}

// Preset Styles
.default {
  @include buttonStyle($colorWhite, $colorText, $colorText, $colorSteel);
}

.primary {
  @include buttonStyle($colorActionable);
}

.neutral {
  @include buttonStyle($colorWhite, $colorText, $colorSecondary, $colorSteel);
}

.destructive {
  @include buttonStyle($colorAlert);
}

.negative {
  @include buttonStyle($colorWhite, $colorText, $colorAlert, $colorSteel);
}

.secondary {
  @include buttonStyle($colorWhite, $colorText, $colorSecondary, $colorSteel);
}

.attachmentButton {
  @include buttonStyle($colorWhite, $colorSecondary, $colorSecondary, $colorWhite);
  padding: 0;
}

.selected {
  @include buttonStyle($colorWhite, $colorText, $colorSelected, $colorGray);
}

.minimal {
  cursor: pointer;

  @include buttonStyle($colorWhite, $colorText, $colorSecondary, $colorGray);
}

.backButtonTransparentContainer {
  border: 0;
  min-width: 60px;
}

.backButtonTransparentButtonElement {
  @include buttonStyle($colorSecondary, $colorText, $colorSecondary, $colorGray);
  border: 0;
}

.backButtonTransparentLabelText {
  color: $colorSecondary;
  font-size: 1rem;
  font-weight: 400;
  padding-left: 0;
}

.backButtonTransparentLeftArrow {
  padding-right: 0;
}
