.flagAnimation {
  animation-duration: .25s;
}

// Center Top
@keyframes slideInDown {

  0% {
    transform: translate3d(0, -100%, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOutUp {

  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, -100%, 0);
  }
}

.flagSlideInDown {
  animation-name: slideInDown;
}

.flagSlideOutUp {
  animation-name: slideOutUp;
}

// Left
@keyframes slideInRight {

  0% {
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOutLeft {

  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

.flagSlideInRight {
  animation-name: slideInRight;
}

.flagSlideOutLeft {
  animation-name: slideOutLeft;
}

// Right
@keyframes slideInLeft {

  0% {
    transform: translate3d(100%, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOutRight {

  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(100%, 0, 0);
  }
}

.flagSlideInLeft {
  animation-name: slideInLeft;
}

.flagSlideOutRight {
  animation-name: slideOutRight;
}

// Center Bottom
@keyframes slideInUp {

  0% {
    transform: translate3d(0, 100%, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOutDown {

  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, 100%, 0);
  }
}

.flagSlideInUp {
  animation-name: slideInUp;
}

.flagSlideOutDown {
  animation-name: slideOutDown;
}
