@import "src/common-ui/variables";

@import "src/common-ui/Labels/tagStyle";

.projectCardDetail {
  display: grid;
  grid-template-rows: auto auto;
  align-items: center;
  background-color: $colorWhite;

  &.mobile {
    padding: $spacer-small;
    outline: $border solid $colorLightGray;
  }

  .projectCardDetailLabel {
    @include textSmallLabel;

    @include desktop() {
      @include textSmallLabel($colorDarkGray, $font-weight-regular, 13px);
    }
  }

  .projectCardDetailValue {
    @include textBody;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include desktop() {
      @include textSmallLabel;
    }
  }

  .blueUnderline {
    text-decoration: underline;
    word-break: break-all;

    @include textBody($colorSecondary);
  }

}

.projectSiteIndicator {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  min-width: 120px;

  @include textSmallLabel($colorText);

  @include svgSize($spacer, $spacer);

  &.mobile {
    @include textBody;

    @include svgSize($spacer, $spacer);

    .siteDetailText {
      left: calc(#{$spacer} + #{$spacer-small});
    }
  }
}

.siteDetailText {
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  left: calc(#{$spacer} + #{$spacer-small});
  right: 0;
  cursor: default;

  &:not(.skipTransition) {
    @include transition(.25s);
  }

  &.hidden {
    opacity: 0;
  }
}

.subdispatchIcon {
  margin-left: $spacer-small;
  display: inline-flex;
  border-radius: 50%;
  border-color: $colorBrand;
  background-color: $colorBrand;
  width: 1.6rem;
  height: 1.6rem;
  vertical-align: text-top;
  justify-content: center;
  align-items: center;
  align-self: center;

  @include svgColor($colorBlack);

  @include svgSize(1.1rem, 1.1rem);
}

.projectCardHeader {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: $spacer-large;
  justify-content: space-between;
  background-color: $colorWhite;

  .projectCardHeaderLeft {
    display: grid;
    grid-template-columns: auto auto;
  }

  &.mobile {
    grid-template-columns: 1fr auto;
  }

  .dateDisplay {
    display: flex;
    flex-direction: column;
    border-right: $border solid $colorLightGray;
    margin-right: $spacer-small;
    padding: $spacer-small $spacer;
    text-align: center;
    text-transform: uppercase;

    .dateMonth {
      letter-spacing: $spacer-small*0.5;
      margin-right: -$spacer-small*0.5;

      @include textSmallLabel($colorBlack, $font-weight-semibold, $font-size-xsmall);
    }

    .dateNumber {
      line-height: 1rem;

      @include textLargeLabel($colorBlack, $fontSize: $font-size-large);
    }

    .dateWeekday {
      letter-spacing: $spacer-small*0.5;
      margin-right: -$spacer-small*0.5;

      @include textSmallLabel($colorText, $font-weight-semibold, $font-size-xsmall);
    }
  }

  .projectCardHeaderInfo {
    display: grid;
    grid-template-columns: auto auto 2fr;
    padding: $spacer-small;

    &.mobile {
      display: grid;
      grid-template-columns: auto;
    }
  }

  .projectCardHeaderProgressBar {
    display: grid;
    grid-auto-flow: column;
  }

  .projectCardHeaderControls {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;
    grid-gap: $spacer;
    padding: $spacer-medium;
    align-content: center;

    &.mobile {
      grid-gap: $spacer-medium;
      align-content: baseline;
    }

    &.history {
      grid-gap: 0;
      padding: 0;
      align-content: flex-start;
    }
  }
}

.projectCardTag {
  max-width: 100px;
  margin: $spacer-medium 0;
}

.projectCardClassicTag {
  @include tagStyle($colorLightGray, $colorText, $colorBlack, $colorDarkGray);
}

.projectCardSmartTag {
  @include tagStyle($colorPurple, $colorWhite, $colorBrand);
}

.projectGeneratedTag {
  @include tagStyle($colorLightGray, $colorText, $colorBlack, $colorDarkGray);
  max-width: 140px;
}

.smartProjectStatus {
  &Container {
    align-items: center;
    background-color: $colorWhite;
    padding: $spacer $spacer;
    outline: $border solid $colorLightGray;
    display: flex;

    @include textSmallBody($colorDarkGray);

    &.mobile {
      border-bottom: $border-thick solid $colorPurple;
    }
  }

  &Spinner {
    margin-right: $spacer;
  }
}

// ProjectCard Expand styles
.collapseButton {
  border-radius: 50%;
  font-size: 0;

  @include svgColor($colorText);

  @include transition(.25s);

  &:hover {
    cursor: pointer;
    background-color: $colorLightGray;
  }

  svg {
    transform: rotate(90deg);
  }

  &.collapsed {

    svg {
      transform: rotate(270deg);
    }
  }
}

.mobileActionButton {
  margin: 16px;
  margin-left: 0px;
  padding-left: 0px;
  width: 100%;
  border: 0px;
  justify-content: flex-start;
}
