// Colors
$colorBrand: #F6CC46;
$colorActionable: #1E9F76;
$colorSecondary: #106FEE;
$colorSelected: #B538DE;
$colorAlert: #E2164A;
$colorMissing: #ECF4FE;
$colorIdle: #E9610D;
$colorBlack: #000000;
$colorText: #4A4A4A;
$colorDarkGray: #767676;
$colorGray: #9B9B9B;
$colorMediumGray: #ADADAD;
$colorSteel: #D8D8D8;
$colorLightGray: #EEEEEE;
$colorVeryLightGray: #E5E5E5;
$colorWhite: #FFFFFF;
$colorPurple: #270166;
$colorLightGreen: #B0D8C8;
$colorHoverGray: #FAFAFA;
$colorBrown: #4D4D4D;
$colorButtonHover: #26ca96;
$colorIceBlue: #2493ff;
// Media breakpoints
$break-mobile: 1010px;

// Fonts
$font: "Hind", -apple-system, system-ui, BlinkMacSystemFont, Arial, sans-serif;
$appleFont: -apple-system, BlinkMacSystemFont, sans-serif;
$headingFont: "Assistant", sans-serif;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-normal: 500;
$font-weight-semibold: 600;
$font-weight-bold: 800;
$font-weight-xbold: 900;


$font-size-default: 1rem;
$font-size-title: 2rem;
$font-size-heading: 1.65rem;
$font-size-large: 1.3rem;
$font-size-small: .85rem;
$font-size-xsmall: .7rem;
$font-size-px-small: 12px;
$font-size-px-normal: 13px;
$font-size-px-big: 14px;
$font-size-px-bigger: 18px;
$font-size-px-regular: 19px;

// Border
$border: 1px;
$border-thick: 2px;
$border-extra-thick: 3px;
$border-radius: 4px;
$border-radius-medium: 6px;
$border-radius-ml: 8px;
$border-radius-large: 9px;

// Border Radius
$border-radius-round: 15px;

// Margins
$margin-small: 4px;
$margin-regular: 8px;
$margin-normal: 16px;
$margin-large: 32px;
$margin-negative-uncover: -1rem;

// Padding
$padding-small-px: 4px;
$padding-normal: 12px;
$padding-medium: 16px;

// Spaces
$spacer: 1rem;
$spacer-large: 2rem;
$spacer-medium: .5rem;
$spacer-small: .35rem;
$spacer-bottom-gutter: 5rem;

$spacer-px-small: 12px;
$spacer-px: 16px;
$spacer-xxl: 28px;
$padding-small: 1px;
$padding-regular: 8px;
$padding-large: 10px;
$padding-xlarge: 12px;
$width-xxl: 480px;
$width-full: 100%;

// Icon Sizes
$icon-size-small: 1rem;
$icon-size: 1.3rem;
$icon-size-large: 2rem;

// Line Height
$line-height-small: 20px;
$line-height-default: 26px;

$height-large: 12px;
$height-normal: 32px;
$height-small: 16px;
$height-small-normal: 10px;


// Apply styles only to non-mobile browsers
//
// Include this _after_ mobile styles to override them.
@mixin desktop() {
  @media only screen and (min-width: $break-mobile) {
    @content;
  }
}

// Text
@mixin _headingFont($color, $fontSize, $fontWeight) {
  font-family: $headingFont;
  color: $color;
  font-size: $fontSize;
  font-weight: $fontWeight;
}

@mixin _bodyFont($color, $fontWeight, $fontSize) {
  font-family: $font;
  color: $color;
  font-size: $fontSize;
  font-weight: $fontWeight;
}

@mixin bodyFontFamily($color, $fontWeight, $fontSize, $font) {
  font-family: $font;
  color: $color;
  font-size: $fontSize;
  font-weight: $fontWeight;
}

@mixin textTitle($color: $colorBlack, $fontSize: 1.9rem, $fontWeight: $font-weight-xbold) {
  @include _headingFont($color, $fontSize, $fontWeight);
}

.textTitle { @include textTitle; }

@mixin textHeader($color: $colorText, $fontSize: 1.4rem, $fontWeight: $font-weight-xbold) {
  @include _headingFont($color, $fontSize, $fontWeight);
}

.textHeader { @include textHeader; }


@mixin textSubheader($color: $colorText, $fontSize: 1.35rem, $fontWeight: $font-weight-xbold) {
  @include _headingFont($color, $fontSize, $fontWeight);
}

.textSubheader { @include textSubheader; }

@mixin tertiaryHeader($color: $colorText, $fontSize: 1.1rem, $fontWeight: $font-weight-xbold) {
  @include _headingFont($color, $fontSize, $fontWeight);
}

.tertiaryHeader { @include tertiaryHeader; }

@mixin textSubSubheader($color: $colorText, $fontSize: .9rem, $fontWeight: $font-weight-xbold) {
  @include _headingFont($color, $fontSize, $fontWeight);
}

.textSubSubheader { @include textSubSubheader; }

@mixin textBody($color: $colorText, $fontWeight: $font-weight-light, $fontSize: .9rem) {
  @include _bodyFont($color, $fontWeight, $fontSize);
}

.textBody { @include textBody; }

@mixin textSmallBody($color: $colorText, $fontWeight: $font-weight-light, $fontSize: .8rem) {
  @include _bodyFont($color, $fontWeight, $fontSize);
}

.textSmallBody { @include textSmallBody; }

@mixin textBold($color: $colorBlack, $fontWeight: $font-weight-semibold, $fontSize: .9rem) {
  @include _bodyFont($color, $fontWeight, $fontSize);
}

.textBold { @include textBold; }

@mixin textLargeLabel($color: $colorBlack, $fontWeight: $font-weight-regular, $fontSize: 1rem) {
  @include _bodyFont($color, $fontWeight, $fontSize);
}

.textLargeLabel { @include textLargeLabel; }

@mixin textRegularLabel($color: $colorBlack, $fontWeight: $font-weight-regular, $fontSize: .9rem) {
  @include _bodyFont($color, $fontWeight, $fontSize);
}

.textRegularLabel { @include textRegularLabel; }

@mixin textSmallLabel($color: $colorBlack, $fontWeight: $font-weight-regular, $fontSize: .85rem) {
  @include _bodyFont($color, $fontWeight, $fontSize);
}

.textSmallLabel { @include textSmallLabel; }

@mixin textNavigation($color: $colorBlack, $fontSize: .8rem, $fontWeight: $font-weight-xbold) {
  @include _headingFont($color, $fontSize, $fontWeight);
}

.textNavigation { @include textNavigation; }

// Backgrounds
@mixin backgroundFlat {
  background-color: $colorWhite;
  border: $border solid $colorLightGray;
}

.backgroundFlat { @include backgroundFlat; }

@mixin backgroundClick {
  background-color: $colorWhite;
  box-shadow: 0 0 7px 0 rgba($colorBlack, .33);
}

.backgroundClick { @include backgroundClick; }

@mixin backgroundHover {
  background-color: $colorWhite;
  box-shadow: 0 0 7px 0 rgba($colorBlack, .5);
}

.backgroundHover { @include backgroundHover; }

@mixin backgroundSelected {
  border: $border solid $colorSelected;
}

.backgroundSelected { @include backgroundSelected; }

@mixin backgroundAlert {
  border: $border solid $colorAlert;
}

.backgroundAlert { @include backgroundAlert; }

@mixin backgroundFill($color) {
  background-color: $color;
}

// Mixins
@mixin transition($timing) {
  -moz-transition: all $timing ease-in-out;
  -o-transition: all $timing ease-in-out;
  -webkit-transition: all $timing ease-in-out;
  transition: all $timing ease-in-out;
}

@mixin calc($key, $value) {
  #{$key}: -webkit-calc(#{$value});
  #{$key}: -moz-calc(#{$value});
  #{$key}: calc(#{$value});
}

@mixin svgColor($color) {

  svg {

    * {
      fill: $color;
    }
  }
}

@mixin svgSize($width, $height) {

  svg {
    width: $width;
    height: $height;
  }
}

@mixin spinner($color, $size) {
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: "";
    width: $size;
    height: $size;
    border-radius: 50%;
    border: $size*0.1 solid $color;
    border-color: $color transparent;
    animation: loadingSpinnerAnimation 1.2s linear infinite;
  }

  @keyframes loadingSpinnerAnimation {

    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
