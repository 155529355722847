@import "src/common-ui/variables";

.profilePhoto {
  width: 2rem;
  height: 2rem;

  &.large {
    width: 4rem;
    height: 4rem;
  }
}

@mixin iconborder($color) {
  background-color: $color;
  padding: $border-thick;
  border-radius: 100%;
  display: flex;
}

@mixin genericIcon() {
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
}

.iconborderActive {
  @include iconborder($colorActionable);
}

.iconborderHire {
  @include iconborder($colorSteel);
}

.iconborderMissing {
  @include genericIcon;

  @include iconborder($colorAlert);
}

.iconborderCancelled {
  @include genericIcon();

  @include iconborder($colorSteel);
}

.iconborderIdle {
  @include iconborder($colorIdle);
}

.iconborderLost {
  @include iconborder($colorBlack);
}

.iconborderNone {
  @include iconborder(transparent);
}

.iconborderNotAvailable {
  @include iconborder($colorText);
}

.initials {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: $spacer-small*0.5;

  @include textLargeLabel($colorWhite, $font-weight-semibold);

  @include svgColor($colorWhite);

  &.large {
    @include textLargeLabel($colorWhite$font-weight-semibold, $fontSize: 2rem);
  }
}

.priorityDefault {
  @include iconborder($colorSteel);
}

.priorityHigh {
  @include iconborder($colorAlert);
}

.priorityMedium {
  @include iconborder($colorIdle);
}
