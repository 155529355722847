@import "src/common-ui/variables";

.imagePreviewHigh,
.imagePreviewMedium,
.imagePreviewLow,
.imagePreview {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: $colorWhite;
  border: 2px $colorSteel solid;
  overflow: hidden;

  &.large {
    width: 5rem;
    height: 5rem;
  }
}

.imagePreviewLow {
  border: 2px $colorGray solid;
}

.imagePreviewMedium {
  border: 2px $colorIdle solid;
}

.imagePreviewHigh {
  border: 2px $colorAlert solid;
}

.circleLogo {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.initialsHigh,
.initialsMedium,
.initials {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: $spacer-small*0.5;
  background-color: $colorSteel;

  @include textLargeLabel($colorWhite, $font-weight-semibold);

  &.large {
    width: 5rem;
    height: 5rem;
    padding-top: $spacer-small;

    @include textLargeLabel($colorWhite, $font-weight-semibold, $fontSize: 3rem);
  }
}

.initialsMedium {
  background-color: $colorIdle;
}

.initialsHigh {
  background-color: $colorAlert;
}

.imagePreviewRectangle {
  background-color: $colorWhite;
}

.rectangleLogo {
  max-height: 2.5rem;
  max-width: 7rem;
  object-fit: contain;
  vertical-align: middle;
}

.companyNoLogo {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  vertical-align: middle;
  grid-gap: $spacer-medium;
  padding: $spacer-small;
  width: 100%;

  &:hover {
    cursor: pointer;
    background-color: $colorLightGray;
  }
}

.companyNameText {
  text-align: left;

  @include textHeader;
}

.homeLink {
  padding-top: $spacer;

  @include textBold($colorBlack, $fontSize: 1.5rem);
}
