@import "src/common-ui/variables";

@mixin flag($colour, $linkColour: $colour, $iconColour: $linkColour) {
  background: linear-gradient(0deg, rgba($colour, .1), rgba($colour, .1)), linear-gradient(0deg, $colorWhite, $colorWhite);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, .15);
  border-radius: 4px;

  max-width: 360px;
  width: 100%;

  display: flex;

  .stripe {
    width: 4px;
    background: $colour;
    border-radius: 2px 0px 0px 2px;
  }

  .contentContainer {
    display: grid;
    grid-template-columns: auto 1fr auto;
    padding: $spacer;
    grid-gap: $spacer-medium;
    width: 100%;
  }

  .content {
    display: grid;
    grid-auto-flow: row;
    grid-gap: $spacer-medium;
    align-content: center;
  }

  .action {
    @include textBody($linkColour, $font-weight-regular, $font-size-default);
  }

  .title {
    @include textBody($colorText, $font-weight-regular, $font-size-default);
  }

  .icon,
  .closeIcon {
    margin-top: 3px;

    @include svgSize(18px, 18px);
  }

  .icon {
    @include svgColor($iconColour);
  }

  .closeIcon {
    @include svgColor($colorGray);
  }
}

.flagSuccess {
  @include flag($colorActionable, $iconColour: auto);
}

.flagInformation {
  @include flag($colorSecondary);
}

.flagWarning {
  @include flag($colorBrand, $colorText);
}

.flagCritical {
  @include flag($colorAlert, $iconColour: auto);
}

.basicFlag {
  background: $colorBlack;
  text-align: center;
  opacity: 0.8;
  border-radius: $border-radius;
  padding: 8px 16px;
  margin: auto;

  @include textBody($colorWhite, $font-weight-semibold, 14px);
}

// Styles for react-notifications-component
:global .notifications-component {
  overflow: hidden;

  :global .notification-container--bottom-left {
    left: $spacer;
    bottom: 0;

    @include desktop() {
      left: 20px;
      bottom: 20px;
    }
  }
}

:global .notification__item {
  box-shadow: none !important;
  margin-bottom: 0px !important;
}
