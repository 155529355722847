@import "src/common-ui/variables";
@import "src/common-ui/Labels/tagStyle";

.searchWrapper {
  flex-direction: column;
  position: relative;
  display: flex;

  input::placeholder {
    @include textBody($colorGray);

    &.advancedSearchWrapper {
      min-width: 12rem;
    }
  }

  .wrapSelection {
    margin: 0;
    padding: 0;
    position: relative;
  }
}

.searchArea {
  display: flex;
}

.sortButton {
  padding-left: $spacer-small;
  border-color: $colorGray;

  @include svgColor($colorGray);
}

.sortBox {
  z-index: 1;
  background: $colorWhite;
  box-shadow: 1px 1px 8px 0 rgba($colorGray, 0.5);
  position: absolute;
  top: 100%;
  right: 0;
  left: auto;
  display: flex;
  margin-top: $spacer-medium;
  border-radius: $border-radius;
  flex-direction: column;
  padding-top: $spacer-medium;
  padding-bottom: $spacer-medium;
  padding-right: $spacer-medium;
  padding-left: $spacer-medium - $spacer-small;

  .sortBoxText {
    @include textSmallLabel();
    padding-left: $spacer-small;
  }

  .sortBoxItems {
    padding-top: $spacer-small;
    display: flex;
    flex-wrap: wrap;
  }
}

.eyeglassIcon {
  position: absolute;
  padding-left: $spacer-small;
  z-index: 1;
  top: auto;
  bottom: auto;
  height: 100%;
  display: flex;
  align-items: center;

  @include svgColor($colorGray);
  @include svgSize(1.25rem, 1.25rem);
}

.filterList {
  margin-top: $spacer-small;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@mixin selectedItemDiv() {
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: $spacer-small * 0.5;
  align-items: center;
  padding: 0.1rem (.75 * $spacer);
  border-radius: 24px;
  line-height: 1rem;
}

@mixin selectedItemDivCloseButton() {
  display: flex;
  cursor: pointer;
  align-items: center;
  border: 0;
  padding: 0;
}

.clearText {
  padding-top: calc(#{$spacer-small}/2 + #{$spacer}/10);
  padding-bottom: $spacer-small*0.5;
  cursor: pointer;

  @include textSmallLabel(inherit);
}

.clearAllButton {
  @include tagStyle($colorBlack, $colorWhite, $colorBlack, $colorBlack);

  @include selectedItemDiv;

  .closeIcon {
    @include selectedItemDivCloseButton();
    @include svgColor($colorWhite);

    @include svgSize(1rem, 1rem);
  }

  &:hover {
    @include transition(.25s);
    background: $colorText;
  }
}

.clearAllFiltersButton {
  cursor: pointer;

  @include textRegularLabel($colorSecondary);
}
