@import "src/common-ui/variables";

.timePicker {
  position: relative;

  .errorMessage {
    display: none;
  }

  &.embedded {
    height: 100%;
  }

  &.disabled {
    cursor: not-allowed;

    .timePickerField {
      background: $colorLightGray;
    }
  }

  &.disabled * {
    pointer-events: none;
  }

  &.error {
    border: $border solid $colorAlert;
    border-radius: $border-radius;

    .errorMessage {
      position: absolute;
      top: 10px;
      right: 30%;

      @include desktop {
        display: block;
        right: 25%;
      }
    }
  }
}

.timePickerField {
  border: 1px solid $colorLightGray;
  border-radius: $border-radius;
  padding: $spacer-medium;
  height: 2.5rem;
  width: 100%;

  @include textBody;

  @include transition(.25s);

  &.embedded {
    background-color: transparent;
    height: 100%;
    border: 0;
  }

  &.focused {
    border-radius: $border-radius $border-radius $border-radius 0;

    @include backgroundSelected;

    @include backgroundHover;
  }
}

.timePickerField::placeholder {
  @include textBody($colorGray);
}

.timePickerMenu {
  height: 8rem;
  background-color: $colorWhite;
  display: inline-flex;
  border-radius: 0 0 $border-radius $border-radius;
  position: absolute;
  left: 0;
  top: calc(100% - #{$border});
  z-index: 999;
  box-shadow: 0 3px 7px rgba($colorBlack, .33);
  width: 100%;

  @include backgroundSelected;

  @include transition(.25s);

  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
}

.timePickerScroller {
  overflow-y: auto;
  width: 33.3%;
}

.timePickerOption {
  padding-left: $spacer-small;
  padding-right: $spacer;

  &.selected {
    background-color: lighten($colorSelected, 30%);
  }

  &:hover {
    cursor: pointer;
    background-color: lighten($colorSelected, 30%);
  }
}

.timePickerIcon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
  right: calc(#{$spacer-medium} + #{$border});
  background-color: $colorWhite;
  border-radius: $border-radius;

  @include svgColor($colorGray);

  @include svgSize(.8rem, .8rem);

  svg {
    transform: rotate(-90deg);
  }

  &.expanded {
    cursor: pointer;
    background-color: $colorSelected;

    @include svgColor($colorWhite);

    svg {
      transform: none;
    }
  }
}
