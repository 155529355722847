@import "src/common-ui/variables";

.formHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacer;
}

.statusTemporaryLabel {
  display: flex;
  align-items: center;
}
