@import "src/common-ui/variables";

@import "src/common-ui/Button/buttonStyle";

.popupContainer {
  position: relative;
}

.popupBox {
  pointer-events: none;
  position: absolute;
  right: 0;
  top: calc(100% + #{$spacer-medium});
  border-radius: $border-radius-medium;
  padding: $spacer;
  z-index: 999;
  visibility: hidden;
  opacity: 0;

  @include transition(.25s);

  @include backgroundHover;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);

  &.open {
    pointer-events: auto;
    visibility: visible;
    opacity: 1;
  }

  &.left {
    left: 0;
    right: auto;
  }
}

.confirmationPopupControls {
  display: grid;
  grid-auto-flow: column;
  grid-gap: $spacer-medium;
  margin: $spacer-medium 0;
}

.confirmationPopupBackButton {
  @include buttonStyle($colorWhite, $colorGray, $colorText, $colorGray);
}
