@import "src/common-ui/variables";

.objectTextField {
  position: relative;

  &.embedded {
    background-color: transparent;
    height: 100%;
    border: 0;
  }
}

.objectTextInputField {
  border: 1px solid $colorLightGray;
  border-radius: $border-radius;
  padding: $spacer-medium;
  height: 2.5rem;
  width: 100%;

  @include textBody;
  &.embedded {
    background-color: transparent;
    height: 100%;
    border: 0;
  }

  &.autocompleted {
    padding: $spacer-medium $spacer-medium $spacer-medium $spacer-large;
    @include textRegularLabel;

    &.setcolor {
      color: $colorText;
      font-weight: $font-weight-light;
    }
  }

  &.focused {
    border-radius: $border-radius $border-radius $border-radius 0;

    @include backgroundSelected;

    @include backgroundHover;
  }
}

.objectDropdownMenu {
  margin: 0 !important;
  border: $border solid $colorSelected !important;
  border-top: 0 !important;
  border-radius: 0 0 $border-radius $border-radius !important;
  box-shadow: 0 0 7px 0 rgba($colorBlack, .5) !important;
  position: absolute;
  background-color: $colorWhite;
  z-index: 999;
  width: 100%;
  max-height: 240px;
  overflow-y: auto;

  @include svgSize($spacer, $spacer);

  @include textBody;

  @include backgroundSelected;

  &.hidden {
    visibility: hidden;
  }
}

.option {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: $spacer-small;

  @include textBody;

  &.selected {
    background-color: $colorLightGray;
  }

  &.hovered {
    cursor: pointer;
    background-color: $colorHoverGray;
  }
}

.optionLabel {
  padding: 0 $spacer-small;
}

.activeOption {
  background-color: $colorLightGray;
}

.objectSelectedIcon {
  position: absolute;
  margin: 0 0 0 $spacer-small;
  height: 100%;
}

.customOnSelect {
  caret-color: transparent;
}
