@import "src/common-ui/variables";

.timeCard {
  display: grid;
  grid-row-gap: $spacer-medium;
  padding-top: $spacer-medium;
}

.timeCardLine {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
}

.timeCardLine div.icon {
  display: flex;
  align-items: center;

  @include textBold($color: $colorText, $fontSize: $font-size-small);

  @include svgSize(11px, 11px);

  svg {
    margin-right: $spacer-small;
  }

  &.play {
    @include svgColor($colorActionable);

  }

  &.switch {
    @include svgColor($colorSecondary);
  }

  &.stop {
    @include svgColor($colorIdle);
  }

  &.disconnected {
    @include svgColor($colorText);
  }
}
