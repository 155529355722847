@import "src/common-ui/variables";

@mixin emptyContainer($color: $colorGray, $borderColor: $color, $opacity: 0.1) {
  padding: 0;
  border: $border-thick dashed transparent;
  background-color: rgba($color, $opacity);
  height: 100%;
  display: flex;

  @include transition(0.25s);

  [icon] {
    background-color: $borderColor;
  }

  &.dropping {
    border-color: $borderColor;
  }
}

.hireDrop {
  height: 100%;
  width: 100%;

  &.missing {
    @include emptyContainer($colorSecondary);
  }

  &.withHire {
    @include emptyContainer($colorWhite, $colorGray);
  }

  &.cancelled {
    @include emptyContainer($colorLightGray, $colorGray, $opacity: 1);
  }

  &.disabled {
    @include emptyContainer($colorLightGray, $colorLightGray, $opacity: 1);
  }

  .emptyContainer,
  .innerContainer {
    height: 100%;
    width: 100%;
  }
}

.draggableHireChip {
  height: 100%;
  width: 100%;
  cursor: -webkit-grab;
  cursor: grab;
}

// Similar to hireChip, but not column-based since we show the assignment
// status below the name
.assignmentHireChip {
  height: 100%;
  width: 100%;

  @include backgroundClick;

  @include backgroundFill(transparent);

  &.dragging {
    background-color: $colorWhite;
  }

  &.assigned {
    border-radius: 0;
    box-shadow: none;
  }

  &.unassigning {
    transition-duration: 0.01s !important; // Invalidate dropping animation when unassigning
    opacity: 0;
  }
}

.hireDropTablet {
  position: relative;
  border-radius: $border-radius $border-radius 0 0;
  height: 100%;
  width: 100%;

  @include transition(0.15s);

  &.open {
    @include backgroundClick;
  }

  &.missing {
    @include emptyContainer($colorAlert);
  }
  &.cancelled {
    @include emptyContainer($colorLightGray, $colorGray, $opacity: 1);
  }

  .tabletHireList {
    background-color: $colorWhite;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    height: 200px;
    top: 100%;
    width: 100%;
    min-width: 240px;
    z-index: 999;
    border-radius: 0 0 $border-radius-large $border-radius-large;
    padding: $spacer-medium 0;
    overflow: hidden;
    box-shadow: 0 3px 7px rgba($colorBlack, 0.33);

    @include transition(0.15s);

    &.open {
      visibility: visible;
      opacity: 1;
    }
  }

  .tabletHireListScroller {
    overflow: auto;
    height: 100%;
    padding: 0 $spacer-medium $spacer-medium $spacer-medium;
  }

  .tabletHireContainer {
    height: 100%;
    width: 100%;
  }
}
