@import "common-ui/variables.scss";

.header {
  margin-bottom: $spacer-medium;
  display: grid;
  grid-template-columns: 9fr 1fr;
  align-items: center;
}

.topShadow {
  width:100%;
  height:1px;
  top:-1px;
  z-index:1;
  box-shadow: 0 1px 5px 1px lightgrey;
  position:absolute;
}

.mapContainer {
  height: 100%;
  width: 100%;
  z-index: 0;
  position: relative;
  overflow-x: hidden;

  > div > div > div {
    // Override z-index of actual mapboxgl-map div to drop it lower than the markers
    // So we can properly control the layering of clickable layers
    z-index: -2;
  }
}

.emptyMap {
  width: 100%;
}

.emptyMapContainer {
  width: 100%;
  z-index: 0;
  position: relative;
}

.emptyMapRefresh {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: $spacer;

  @include svgColor($colorBrand);


  svg {
    cursor: pointer;
  }

  &:hover, &:active {
    @include svgColor($colorSelected);
  }
}

.liveMap {
  position:relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
}

.legend {
  display: flex;
  left: auto;
  right: auto;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  background: $colorWhite;
  padding-right: $spacer;
  border-top-right-radius: $border-radius;
  border-top-left-radius: $border-radius;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.75);
}

.legendEntry {
  padding-left: $spacer;
  display: flex;
  align-items: center;
  svg {
    margin-right: $spacer-small;
  }

  &.online {
    @include svgColor($colorActionable);
  }

  &.idle {
    @include svgColor($colorIdle);
  }

  &.missing {
    @include svgColor($colorAlert);
  }

  &.nostatus {
    @include svgColor($colorGray);
  }
}

.assignment {
  padding-bottom: $spacer;
}

.job {
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.16);
  border-radius: 6px;
  border: 1px solid $colorWhite;
  padding: $spacer-medium $spacer $spacer-medium $spacer-medium;
  display: flex;
  flex-direction: row;
  width:100%;
  margin-bottom:0.65rem;
  .nameSection {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
}

.highlightedJob {
  @include transition(0.25s);
  border: $border solid $colorSelected;
  background-color: lighten($colorSelected,50%);
  box-shadow: 0 0 15px 0 rgba($colorBlack, 0.33);
}

.assignmentIsClosed {
  color: $colorGray;
}


.countSection {
  display: flex;
  flex-direction: row;
}

.countBadge {
  margin-right: $spacer-small;
}

.textSection {
  display: flex;
  flex-direction: row;
}

.projectDetailSection {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  grid-gap: $spacer-medium;
  justify-content: center;
  align-items: center;

  .detailHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include textSubheader($fontSize: $font-size-default);

    .shareButton {
      height: $icon-size-large;
      padding: $spacer-medium;
    }
  }
}

.projectDetails {
  display: grid;
  grid-template-rows: auto;

  .projectDetailsRow {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: $spacer-small;

    .label {
      @include textSmallLabel($color: $colorText);
    }

    .value {
      @include textSmallBody($fontSize: .85rem);
    }
  }

}

.toggleSection {
  flex: 4;
}

.sideBarHeader {
  @include textHeader;
}

// For sidebar - this should be for tablet or larger
.liveSidebar {
  min-width: 18.5rem;
  overflow-y: scroll;
}

.liveSidebarContents {
  width: 100%;
}

.companySectionSidebar {
  padding-top: $spacer;
}

.assignment {
 .nameSection {
    line-height: 1.1;
    flex: 12;
    display:flex;
    align-items: center;

    @include textSubheader($fontSize: $font-size-default);
  }

  .addressText {
    @include textBody;
  }

  .jobNumber {
    @include textBody;
  }
}

// Overrides for child components, so we need the wrap around a parent selector to work with css selectors
.countSection {

  .count {
    margin-right: $spacer-small;
    height: 1.25rem;
    width: 1.25rem;
  }

  .countOnline {
    background-color: $colorActionable;
  }

  .countIdle {
    background-color: $colorIdle;
  }

  .countDisconnected {
    background-color: $colorText;
  }

  .countBadData {
    background-color: $colorAlert;
  }
}

div.popup {
  border-radius: unset;
  padding: 0;
}

.filterPopup {
  padding-top: $spacer;
}

.filterMenu {
  padding: $spacer-medium*1.5 $spacer-medium*0.5;
}

.driverRoutesIcon {
  display: flex;

  @include svgSize($spacer, $spacer);

  @include svgColor($colorSecondary);
}
