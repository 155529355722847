@import "src/common-ui/variables";

@import "src/common-ui/Form/Dropdown/styles.module.scss";

@import "../styles.module.scss";

@import "src/common-ui/Labels/tagStyle";

.searchSelect {
  padding-left: $icon-size;

  @include textBody;
}

.searchInput {
  position: relative;
  width: 100%;

  @include textBody;
}

//SelectedTermOption
.selectedTerm {
  @include tagStyle(lighten($colorSelected, 42%), $colorText, $colorSelected, $colorSelected);
  margin-bottom: $spacer-small;
  @include selectedItemDiv;

  .closeIcon {
    @include selectedItemDivCloseButton;
    @include svgSize(1rem, 1rem);
  }

  &:hover {
    @include transition(0.25s);
    color: lighten($colorText, 12%);

    .closeIcon {
     @include svgColor(lighten($colorSelected, 12%));
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.selectedText {
  padding-top: calc(#{$spacer-small}/2 + #{$spacer}/10);
  padding-bottom: $spacer-small*0.5;

  @include textSmallLabel(inherit);
}

.inputRadio {
  flex-grow: 1;
  border: $border solid $colorGray;
  border-radius: $border-radius;
  position: relative;
  align-items: center;
  cursor: pointer;
  margin-left: $spacer-small;
  padding-left: $spacer-small;
  padding-right: $spacer-small;

  @include textSmallLabel;

  color: $colorGray;
  margin-bottom: $spacer-small;

  &.selected, &:hover {

    @include transition(0.25s);

    background: lighten($colorSelected, 42%);
    border: $border solid $colorSelected;
    color: $colorSelected;
  }

  &:disabled {
    background: $colorLightGray;
    border: $border solid $colorLightGray;
    color: $colorGray;
    cursor: not-allowed;
  }

  &.left {
    margin-left: 0;
  }
}
