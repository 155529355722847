@import "src/common-ui/variables";

.companyDropdownWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  position: relative;
  margin: $spacer-small 0;

  .dropdownContainer {
    padding: $spacer-small 0;
    position: absolute;
    top: 35px;
    left: 4px;
    min-width: 260px;
    z-index: 1;
    border-radius: $border-radius;
    align-items: left;

    @include backgroundClick;
  }
}

.companyOptionWrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  grid-gap: $spacer-medium;
  width: 100%;
}

.dropdownOptionContainer {
  padding: $spacer-small $spacer;

  &:hover {
    background: $colorLightGray;
    cursor: pointer;
  }
}

.header {
  padding-left: $spacer;
  align-items: left;

  @include textSubheader;
}

.nineDots {
  display: flex;
  padding: 0;
}
