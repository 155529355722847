@import "src/common-ui/variables";

.clientShareModal {
  padding-top: 10px;
  display: flex;
  flex-direction: column;

  .description {
    padding: 0 0 $spacer;

    @include textBody;
  }

  .bold {
    font-weight: $font-weight-xbold;
  }

  .clientBox {
    display: flex;
    align-items: center;
    height: 2.15rem;
    border-radius: $border-radius;
    border: 1px solid $colorSteel;
    background: $colorLightGray;
    width: 100%;
    margin-bottom: 10px;
    padding-left: 10px;

    @include textBody($color: $colorDarkGray);
  }

}
