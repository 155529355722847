@import "src/common-ui/variables";

@import "src/common-ui/Button/buttonStyle";

@import "common-ui/Labels/tagStyle";

@import "components/Common/Search/styles.module.scss";

$colorDarkerGray: #4D4D4D;

.filterContainer {
    width: 360px;
    min-width: 100%;
    height: 394px;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr) auto;
    border-radius: 6px;

    &.smallContainer {
        width: 260px;
        min-width: 110%;
        height: 27vh;
        display: flex;
    }

    &.mobile {
        width: 0;
    }

    .filterHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: $spacer;

        @include textSubSubheader($colorText);
    }

    .closeIcon {
        height: 24px;
        @include svgSize(1.5rem, 1.5rem);

        @include svgColor($colorDarkGray);

        &:hover {
            cursor: pointer;
        }
    }
    .tagsListSection {
        margin: 0 (-$spacer*3*0.25);

        &.smallContainer {
            width: 99%;
        }
    }

    .tagsButtonsSection {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        padding-top: $spacer;
        grid-gap: $spacer;
    }

    .saveButton {
        @include buttonStyle($colorActionable, $colorWhite);
    }
}

.filterTagsIconButton {
    @include buttonStyle($colorWhite, $colorWhite, $colorDarkGray, $colorSteel);

    &.selected {
        @include buttonStyle($colorSelected, $colorSelected, $colorWhite, $colorSelected);
    }
}

.filterTagsTextButton {
    @include tagStyle($colorWhite, $colorDarkerGray, $colorGray, $colorGray);
    @include selectedItemDiv;

    &.selected {
        @include tagStyle(lighten($colorSelected, 42%), $colorText, $colorSelected, $colorSelected);
        background: lighten($colorSelected, 42%);
    }

    &:hover {
        @include transition(0.25s);
        color: lighten($colorText, 12%);
    }
}

.statusText {
    padding: calc(#{$spacer-small}/2 + #{$spacer}/10) 0 $spacer-small*0.5;
    border: 0;
    cursor: pointer;
    display: flex;
    grid-gap: 5px;

    @include textSmallLabel(inherit);
}
