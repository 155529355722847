@import "src/common-ui/variables.scss";

.sortItem.isLeftMost {
  margin-left: 0;
}

.selectedButton:not(:disabled),
.selectedButton:not(:disabled):hover {
  border-color: $colorSelected;
}

.sortItem {
  margin-left: $spacer-small;

  @include svgColor($colorGray);

  @include svgSize($icon-size, $icon-size);
}

.sortBox {
  z-index: 1;
  background: $colorWhite;
  box-shadow: 1px 1px 8px 0 rgba(0, 0, 0, 0.75);
  position: absolute;
  top: 100%;
  right: 0;
  left: auto;
  display: flex;
  margin-top: $spacer-medium;
  border-radius: $border-radius;
  flex-direction: column;
  padding-top: $spacer-medium;
  padding-bottom: $spacer-medium;
  padding-right: $spacer-medium;
  padding-left: $spacer-medium - $spacer-small;

  .sortBoxText {
    @include textSmallLabel();

    padding-left: $spacer-small;
  }

  .sortBoxItems {
    padding-top: $spacer-small;
    display: flex;
    flex-wrap: wrap;
  }
}

.verticalStyle {
  margin-top: 5px;
  width: 100%;
}
