@import "common-ui/variables.scss";

.overviewContent {
  padding: $spacer-small $spacer;
  flex-grow: 1;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.mobileOverviewContent {
  padding: $spacer-small;
}

.disableScroll {
  overflow-y: visible;
}

@include desktop {
  .overviewContent {
    padding: $spacer 5px $spacer $spacer;
  }
}
