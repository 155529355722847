@import "src/common-ui/variables";

.container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.dropdownContainer {
  position: absolute;
  top: 35px;
  right: 0;
  min-width: 260px;
  z-index: 2;
  border-radius: $border-radius;

  @include backgroundClick;

  &.hidden {
    display: none;
  }
}

.alignLeft {
  left: 0;
  // right: unset;
}
