@import "../../variables.scss";

.formField {
  display: flex;
  flex-direction: column;

  @include textBody;

  &.embedded {
    height: 100%;
  }
}

.formFieldLabelsContainer {
  display: flex;
  justify-content: space-between;
}

.formFieldLabel {
  @include textRegularLabel($colorText, $font-weight-semibold);

  display: flex;
  align-items: center;
}

.formFieldRequired {
  @include textRegularLabel($colorAlert, $font-weight-semibold);
}

.formFieldHint {
  @include textBody($colorDarkGray);
  margin-top: 4px;
}

.formFieldError {
  @include textRegularLabel($colorAlert);
}

.formFieldDescription {
  @include textBody;
  margin-bottom: 10px;
}

.formFieldLabelTooltip {
  display: inline-flex;

  .hoverIconContainer {
    margin-bottom: 4px;
  }
}
