@import "src/common-ui/variables.scss";

.hoverIconContainer {
  margin-bottom: 4px;
  margin-left: 4px;
  display: flex;
  align-items: center;

  .titleInfoIcon {
    display: flex;

    @include svgSize($spacer, $spacer);

    @include svgColor($colorDarkGray);
  }
}
