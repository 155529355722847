@import "src/common-ui/variables";

@import "src/common-ui/Button/buttonStyle";

.assignedCounter {
  border-radius: 100%;
  width: 1.75rem;
  height: 1.75rem;
  background-color: $colorBlack;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  @include textLargeLabel($colorWhite);
}

.oneAssigned {
  background-color: $colorLightGray;
  color: $colorText;
}

.twoAssigned {
  background-color: $colorDarkGray;
}

.threeAssigned {
  background-color: $colorText;
}

.assignedSmart {
  background-color: $colorPurple;

  @include svgColor($colorBrand);
}

.hireTooltipContainer {
  position: relative;
}

.hireTooltip {
  background-color: $colorWhite;
  position: absolute;
  border-radius: $border-radius-large;
  border: 2px solid $colorGray;
  padding: 1.5*$spacer-medium;
  left: 20px;
  top: -60px;
  z-index: 999;
  box-shadow: 5px 0 5px -5px rgba($colorBlack, .33);

  @include transition(.25s);

  &:after,
  &:before {
    content: '';
    position: absolute;
    width: 0;
    bottom: 0;
    left: 15px;
    border-style: solid;
    border-width: 10px 10px 0;
  }
}

.hireTooltip:before {
  border-color: $colorWhite transparent;
  margin-bottom: -7px;
  z-index: 1;
}

.hireTooltip:after {
  border-color: $colorGray transparent;
  border-top-color: $colorGray;
  border-bottom: 0;
  margin-bottom: -10px;
}

.hireChip {
  border-radius: $border-radius*2;
  padding: $spacer-medium;
  display: grid;
  grid-auto-columns: 1fr auto auto;
  grid-auto-flow: column;
  grid-gap: $spacer-medium;
  align-items: center;
  height: 100%;
  width: 100%;

  @include backgroundClick;

  @include backgroundFill(transparent);

  &.dragging {
    background-color: $colorWhite;
    white-space: nowrap;
  }

  &.assigned {
    border-radius: 0;
    box-shadow: none;
  }

  &.unassigning {
    transition-duration: .01s !important; // Invalidate dropping animation when unassigning
    opacity: 0;
  }
}

.hireChipMobile {
  display: grid;
  grid-auto-columns: 1fr auto auto;
  grid-auto-flow: column;
  grid-gap: $spacer-medium;
  align-items: center;
  background-color: $colorWhite;
  border: $border solid $colorLightGray;
  border-radius: $border-radius;
  padding: $spacer-medium;
  margin-top: $spacer-medium;

  @include transition(.25s);

  &.selected {
    border: $border solid $colorSelected;

    @include backgroundHover;

    @include backgroundFill(rgba($colorSelected, .05));
  }
}

.companyClientHireSwitch {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $colorLightGray;
  height: 1.75rem;
  width: 1.75rem;
  border-radius: 50%;

  @include svgSize($spacer, $spacer);

  @include svgColor($colorDarkGray);
}
