@import "../../variables.scss";

.toggleContainer {
  display: flex;
  align-items: center;
}

.toggleControl {
  position: relative;
  height: 1.25rem;
  width: 2.5rem;
  padding: $border-thick;
  border-radius: 1rem;
  box-sizing: content-box;
  background-color: $colorLightGray;
  cursor: pointer;

  @include transition(.25s);

  &.slim {
    padding: $border-thick 0;
    width: 1.75rem;
    height: .25rem;
  }

  &.selected {
    background-color: lighten($colorSelected, 36%);
  }
}

.toggleIndicator {
  position: absolute;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 100%;
  left: $border-thick;
  background-color: $colorGray;

  @include transition(.25s);

  &.slim {
    width: 1rem;
    height: 1rem;
    top: -.25rem;
  }

  &.slimSelected {
    left: 100%;
    transform: translateX(calc(-100%));
    background-color: $colorSelected;
  }

  &.selected {
    left: 100%;
    transform: translateX(calc(-100% - #{$border-thick}));
    background-color: $colorSelected;
  }
}

.toggleLabel {
  margin-left: $spacer-medium;

  @include textBody;
}
