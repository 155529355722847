@import "src/common-ui/variables";

.hoverIconContainer {
    margin-bottom: 3px;
    margin-left: 10px;
    display: flex;
    align-items: center;

    .titleInfoIcon {
        display: flex;

        @include svgSize($spacer, $spacer);
    }
}

