@import "src/common-ui/variables";

.tooltip {
  z-index: 100;
  pointer-events: auto !important;
  opacity: .8 !important;
  background-color: $colorBlack;
  padding: 10px 16px;

  &:hover {
    visibility: visible !important;
  }
}

.tooltipInfoContainer {

  .name {
    @include tertiaryHeader($colorWhite);
  }

  .address {
    @include textRegularLabel($colorWhite);
  }
}

.siteMarker {
  &.unfiltered {
    svg {
      fill: $colorDarkGray;
    }
  }

  &.inactive {
    svg {
      fill: $colorLightGray;
      background: $colorLightGray;
      border: 2px solid $colorMediumGray;
      border-radius: 60px;

      path {
        fill: $colorMediumGray;
      }
    }
  }

  &:hover {
    cursor: pointer;
  }
}
