@import "src/common-ui/variables";

@mixin selectedIndicator {
  width: $spacer-large;
  height: $spacer-large;
  z-index: -1;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $colorWhite;

  @include backgroundFill($colorSelected);
}

.selectedIndicator {
  @include selectedIndicator;
}

.todayIndicator {
  width: $spacer-medium;
  height: $spacer-medium;
  border-radius: 100%;
  background-color: $colorSelected;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

@mixin startIndicator {
  width: $spacer-large;
  height: $spacer-large;
  border: $border solid $colorSelected;
  z-index: -1;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @include backgroundFill(lighten($colorSelected, 43%));
}

@mixin rangeIndicator($start, $end) {
  width: 100%;
  height: 1.5*$spacer;
  z-index: -2;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  @include backgroundFill(lighten($colorSelected, 30%));

  @if $start {
    left: inherit;
    width: 50%;
    right: 0;
  }

  @if $end {
    width: 50%;
    left: 0;
  }
}

.reactCalendarOverrides :global {
  border: $border-thick solid $colorLightGray;
  border-radius: $border-radius;

  .react-calendar {
    background: white;
    font-family: $font;
  }

  .react-calendar__navigation {
    max-height: 30px;
    margin-bottom: 0;
  }

  .react-calendar__navigation__label:disabled {
    background-color: $colorWhite;

    @include textLargeLabel($colorBlack, $font-weight-semibold);
  }

  .react-calendar__navigation__arrow {
    display: flex;
    flex-direction: column;
    padding: $spacer-medium $spacer-small;

    @include textBody($colorGray, $font-weight-light);

    @include svgColor($colorGray);

    @include svgSize(.9rem, .9rem);
  }

  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
  }

  .react-calendar__month-view__weekdays {
    text-transform: none;
    text-align: center;
    border: $border solid $colorLightGray;
    border-left: 0;
    border-right: 0;
    padding: $spacer-small 0;

    @include textRegularLabel($colorBlack, $font-weight-semibold);
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: $colorGray;
  }

  .react-calendar__tile {
    position: relative;
    padding: .6rem 0;
    font-size: $font-size-small;
    text-align: center;
    background: none;
    font-family: $font;
    font-weight: $font-weight-light;
    border-top: $border solid $colorWhite;
    border-left: $border solid $colorWhite;
  }

  .react-calendar__tile:disabled {
    background-color: rgba($colorLightGray, .35);
    border: none;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
  }

  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background-color: $colorWhite;

  }

  .react-calendar__tile--active {
    font-weight: $font-weight-regular;
    color: white;
    background-color: $colorWhite;
  }

  .react-calendar__month-view__days__day {
    z-index: 0;
  }
}

.isOpen {
  border: none;
}

.reactMultiCalendarOverrides :global {

  .react-calendar__tile {
    border-top: 0;
    border-left: 0;
  }

  .react-calendar__tile--active {
    font-weight: inherit;
    color: inherit;
  }

  .react-calendar__tile--active::before {
    content: "";

    @include rangeIndicator(false, false);
  }

  .react-calendar__tile--rangeStart {
    color: $colorSelected;

    &.react-calendar__tile--active::after {
      content: "";

      @include startIndicator;
    }

    &.react-calendar__tile--active::before {
      content: "";

      @include rangeIndicator(true, false);
    }
  }


  .react-calendar__tile--rangeEnd {
    color: $colorWhite;

    &.react-calendar__tile--hasActive::after {
      content: "";

      @include selectedIndicator;
    }

    &.react-calendar__tile--hasActive::before {
      content: "";

      @include rangeIndicator(false, true);
    }
  }


  .react-calendar__tile--rangeBothEnds {
    color: $colorWhite;
    content: "";

    // active when range selection has started but not ended
    &.react-calendar__tile--active {
      color: $colorSelected;
    }

    &.react-calendar__tile--active::after {
      @include startIndicator;
    }

    // hasActive when range selection has ended (and it is one date)
    &.react-calendar__tile--hasActive::after {
      @include selectedIndicator;
    }

    &.react-calendar__tile--active::before,
    &.react-calendar__tile--hasActive::before {
      content: none;
    }
  }
}

.highlighted {
  color: $colorSelected;
}

// Mobile
.dateControl {
  display: flex;
  align-items: center;
  position: relative;

  @include textHeader($colorText, 1.2rem);
}

.dateControlDateDisplay {
  padding: 0 $spacer;
}

.calendarPopup {
  position: absolute;
  left: -$spacer;
  top: 100%;
  margin: 0 $spacer;
  width: 97vw; // Less than 100vw to leave room for scroll bar
  max-width: 400px;
  z-index: 2;
  background-color: $colorWhite;
  visibility: hidden;

  &.visible {
    visibility: visible;
  }
}

.dateControlIcon {
  border: $border-thick solid $colorLightGray;
  border-radius: $border-radius;
  display: flex;
  padding: .5rem;

  @include svgColor(#4D4D4D);

  &:hover {
    cursor: pointer;
  }
}

.dateBorder {
  border: 1px solid $colorSelected;
  border-radius: $border-radius;
}

.calendarPopupRight {
  position: absolute;
  right: -$spacer;
  top: 100%;
  padding: 0 $spacer;
  width: 85vw; // Less than 100vw to leave room for scroll bar and to view more space on the right side
  max-width: 400px;
  z-index: 2;
  background-color: $colorWhite;
   visibility: hidden;

  &.visible {
    visibility: visible;
  }
}
