@import "src/common-ui/variables";

.genericErrorMessage {
  margin: $spacer-small 0;
  color: $colorAlert;
  border-radius: $border-radius;
  padding: $spacer-small*0.5 0;

  @include textSmallLabel;
}

//Keep
.inputFileUpload {
  //Keep
  .fileUpload {
    display: flex;
    align-items: center;
  }
}

.uploadThumbnail {
  margin-right: $spacer;
}

.currentUploaded {
  margin-top: $spacer-medium;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.uploadControlsContainer {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: $spacer-medium;
  align-items: center;
}
