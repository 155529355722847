@import "src/common-ui/variables";

@import "src/common-ui/Button/buttonStyle";

.shareCollaboratorsModal {
  width: 470px;
  max-width: 98vw;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 8rem);

  .shareCollaboratorsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include textHeader;

    @include svgSize(1.5rem, 1.5rem);

    @include svgColor($colorDarkGray);

    svg:hover {
      cursor: pointer;
    }
  }

  .closeButton {
    display: flex;
  }

  .shareCollaboratorsContent {
    flex-grow: 1;
    overflow: auto;
    margin: 0 (-$spacer);
    padding: 0 $spacer;
    display: flex;
    flex-direction: column;
  }

  .collaboratorInput {
    padding: $spacer 0;
    border-bottom: $border solid $colorLightGray;
  }

  .collaboratorList {
    overflow: auto;
    flex-grow: 1;
    margin: 0 (-$spacer);
    padding: 0 $spacer;
  }

  .shareCollaboratorsButtons {
    display: flex;
    justify-content: flex-end;
    border-top: $border solid $colorLightGray;
    padding-top: $spacer;
  }
}

.shareCollaboratorsSubmit {
  @include buttonStyle($colorActionable);
}

.collaboratorShareCard {
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: $spacer;
  padding: $spacer-small $spacer-medium;
  align-items: center;

  .collaboratorRole {
    @include textRegularLabel($colorText);
  }

  .collaboratorMenuButton {
    display: flex;
    align-items: center;
    justify-content: center;

    @include svgColor($colorDarkGray);
    @include svgSize(1rem, 1rem);

    .hoverContainer {
      border-radius: 50%;
      padding: $spacer-medium;

      @include transition(0.25s);

      &:hover {
        cursor: pointer;
        background-color: $colorLightGray;
      }

      .iconContainer {
        display: flex;
        align-items: center;
        position: relative;
      }
    }
  }

  .collaboratorMenuIcon {
    transform: rotate(90deg);
  }

  .collaboratorMenu {
    width: 180px;
    position: absolute;
    opacity: 0;
    background-color: $colorWhite;
    box-shadow: 0 0 8px 0 rgba($colorBlack, .15);
    border-radius: $border-thick;
    right: 0;
    top: calc(100% - #{$spacer-medium});
    padding: $spacer-medium 0;
    pointer-events: none;
    z-index: 2;

    &.visible {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .collaboratorMenuItem {
    padding: $spacer-medium $spacer;

    @include textBody();

    @include transition(.1s);

    &.removeOption {
      color: $colorAlert;
    }

    &:hover {
      cursor: pointer;
      background-color: $colorLightGray;
    }
  }
}
