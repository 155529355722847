@import "src/common-ui/variables";

.mobileTreadLogo {
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 7px 0 rgba($colorBlack, .33);
  z-index: 100;

  @include textBold($colorBlack, $fontSize: 1.5rem);
}
