@import "src/common-ui/variables";

.createSiteButtons {
  display: flex;
  justify-content: flex-end;
  gap: $spacer-medium;
}

// Must extend to the edges of the content area
.createSiteMap {
  height: 400px;
  display: flex;
  padding: $spacer 0;

  @include desktop() {
    height: calc(100% + #{$spacer-large});
    margin: (-$spacer-large) (-$spacer) (-$spacer) (-$spacer);
    padding: 0;
  }
}
