@import "src/common-ui/variables";

$colorCustomHoverGrey: #F3F3F3;

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px $spacer-medium;

  @include desktop() {
    margin: 2px $spacer-medium;
  }

  @include textBody;

  @include svgSize(1.3rem, 1.3rem);

  .label {
    display: flex;
    margin-left: .75rem;
    color: $colorDarkGray;
    font-size: $font-size-default;
    font-weight: $font-weight-regular;

    @include desktop() {
      margin-left: $spacer-small;
    }
  }

  svg * {
    fill: $colorDarkGray;
  }

  &:hover {
    background-color: $colorCustomHoverGrey;
    border-radius: $border-radius;
    border-color: $colorCustomHoverGrey;
  }

  &.mobile {
    justify-content: flex-start;
    padding: .75rem $spacer;

    @include svgSize(1.5rem, 1.5rem);
  }
}

.activeLink {
  border-radius: $border-radius;
  background-color: $colorLightGray;

  svg * {
    fill: $colorBlack;
  }

  .label {
    color: $colorBlack;
  }
}
