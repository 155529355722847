@import "../../variables";

.textInput {
  position: relative;

  &.embedded {
    height: 100%;
  }
}

.textInputField {
  border: 1px solid $colorLightGray;
  border-radius: $border-radius;
  padding: $spacer-medium;
  height: 2.5rem;
  line-height: normal;
  width: 100%;

  @include textBody;

  @include transition(.25s);

  &.currency {
    padding-left: $spacer;
  }

  &.search {
    padding-left: $spacer*2;
    border-color: $colorSteel;
  }

  &.embedded {
    background-color: transparent;
    height: 100%;
    border: 0;
  }

  &.disabled {
    background: $colorLightGray;
  }
}

.textInputField::placeholder {
  @include textBody($colorGray);
}

.textInputField:focus {
  outline: none;

  @include backgroundSelected;

  @include backgroundHover;
}

.inputDecorator {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  @include textBody($colorGray);
}

.minutes {
  left: 1.8em;
}

.currency {
  left: $spacer-medium;
}

.measurement {
  right: $spacer * 2;
}

.searchIcon {
  left: $spacer-medium;
  display: flex;
  align-items: center;

  @include svgColor($colorGray);

  @include svgSize(1.25rem, 1.25rem);
}
