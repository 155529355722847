@import "src/common-ui/variables";

.shareLiveModal {
  min-width: 450px;

  @include desktop {
    width: 20rem;
  }
}

.mobile {
  padding: $spacer;
  width: 100%;
  min-width: 100%;
}
