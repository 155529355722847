@import "../../variables";

.textArea {
  border: 1px solid $colorLightGray;
  border-radius: $border-radius;
  padding: $spacer-medium;
  height: 7.5rem;
  width: 100%;
  resize: none;
  line-height: normal;

  @include textBody;

  @include transition(.25s);

  &.embedded {
    height: 100%;
    border: 0;
    background-color: transparent;
  }
}

.textArea::placeholder {
  @include textBody($colorGray);
}

.textArea:focus {
  outline: none;

  @include backgroundSelected;

  @include backgroundHover;
}
