@import "src/common-ui/variables";

.calendarInputField {
  display: flex;
  border: 1px solid $colorLightGray;
  border-radius: $border-radius;
  padding: $spacer-medium;
  height: 2.5rem;
  width: 100%;

  .dateCalendarIcon {
    padding-right: $spacer-medium;
    @include svgColor($colorGray);
  }

  .dateCalendarText {
    @include textBody;
  }

  &.selected {
    border: 1px solid $colorSelected;
  }
}

.calendarFormFieldPopup {
  position: relative;
  background-color: $colorWhite;
  z-index: 999;

  @include transition(.25s);

  &.hidden {
    visibility: hidden;
    height: 0;
    opacity: 0;
  }

  &.isOverlaid {
    position: absolute;
  }

  &.selected {
    border: 1px solid $colorSelected;
    border-radius: $border-radius;
  }
}
